//@ts-nocheck
import { createReducer } from '@reduxjs/toolkit';
import { doctorDetailsInitialState } from '../../../redux-initial-state';
import { RootState } from '../../../app/store';


const doctorDetailsReducer = createReducer(doctorDetailsInitialState, (builder) => {
  builder
    .addCase('SET_DOCTOR_DETAILS', (state, action) => {
      return {
        ...state,
        ...action.payload
      } 
    })
    .addCase('CLEAR_DOCTOR_DETAILS', (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    })
})

export const doctorDetails = (state: RootState) => state.doctorDetails;

export default doctorDetailsReducer;
