import React, { useState } from 'react'
import { Box, FormControl, TextField, ThemeProvider, Typography } from '@mui/material';
import { makeStyles, createStyles } from "@mui/styles";
import { Alert } from '../../utils';
import theme from '../../theme/theme';
import OtpVerification from './otp-verification/otpVerification';
import { sendUniqueCode } from './uniqueCode.api';

const useStyles = makeStyles(() =>
  createStyles({
    layout: {
      margin: "50px",
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "5px",
      boxShadow: " 5px 5px 8px 0 rgba(0, 0, 0, 0.1)"
    },
  })
);

function UniqueCode() {
  const classes = useStyles({ theme });
  const [uniqueCode, setUniqueCode] = useState("");
  const [uniqueCodeValid, setUniqueCodeValid] = useState(false);

  const inputCapture = (event: any) => {
    setUniqueCode(event.target.value);
    // if (!Utils.constants.spaceRegex.test(value)) {
    //    Alert(3, "BLANK_SPACE");
    //    return false
    // }
  };

  const handleSendCode = (event: any) => {
    event.preventDefault();

    if (uniqueCode.trim().length >= 8) {
      sendUniqueCode(uniqueCode)
        .then((res: any) => {
          let { data } = res;
          setUniqueCodeValid(true);
        })
        .catch((err: any) => {
          let { data } = err.response;
        })
    } else {
      Alert(2, "Unique code must have 8-12 characters!");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.layout}>
        {!uniqueCodeValid &&
          <div className="mb-20 center">
            <Typography variant='h5' className="mb-15">Unique Code</Typography>
            <Typography>
              Please enter the unique code assigned to your account to setup this consultation portal.
            </Typography>
            <Typography>
              You can view the unique code under your web portal profile section.
            </Typography>
            <Typography>
              If you need a new unique code, please call Livia Support at <br /> <b>
                <a
                style = {{color:"inherit",textDecoration:"none"}}
                  key={"0114"}
                  href={`tel: 0114 081492`}
                  title={`Call: 0114 081492`}

                >
                  0114 081492
                </a> /
                <a
                  style = {{color:"inherit",textDecoration:"none",paddingLeft:4}}
                  key={"011412"}
                  href={"tel: +254 732599737"}
                  title={"Call: +254 732599737"}

                >
                  +254 732599737
                </a> / 
                <a
                  style = {{color:"inherit",textDecoration:"none",paddingLeft:4}}
                  key={"011412da"}
                  href={"tel: +254 740869442"}
                  title={"Call: +254 740869442"}

                >
                  +254 740869442
                </a> </b>
            </Typography>

            <FormControl style={{ width: "300px", margin: "20px auto" }}>
              <TextField
                variant="outlined"
                size='small'
                autoFocus
                placeholder='Enter unique code'
                value={uniqueCode}
                onChange={inputCapture}
              />
            </FormControl>

            <div className="flex-center mt-20 mb-20">
              <button
                className='btn btn-primary'
                onClick={handleSendCode}
              >
                Verify
              </button>
            </div>

          </div>
        }

        {uniqueCodeValid &&
          <OtpVerification uniqueCode={uniqueCode} />
        }
      </Box>
    </ThemeProvider>
  )
}

export default UniqueCode
