import axiosInstance from '../../api/axios.instance';
import { END_POINT } from '../../constants/End.point';
import { GetDoctorQueryParmas } from '../../types';

export const getDoctorList = (params: GetDoctorQueryParmas) => {
  const { offset, limit, isOnline, specializationId, search, pharmacyConsultation } = params;
  return axiosInstance.get(
    `${END_POINT.DOCTOR}?specialization_id=${
      specializationId === 'all' ? '' : specializationId
    }&offset=${offset}&limit=${limit}&is_online=${isOnline}&search=${search}&pharmacy_consultation=${pharmacyConsultation}&sorting_option=5`
  );
};

export const getSpecialization = () => {
  return axiosInstance.get(`${END_POINT.SPECIALIZATION}?parent_id=0&offset=0&limit=20&search=&is_online=1`);
};
