import axiosInstance from "../../api/axios.instance";
import { Alert, getDeviceIdFromLS } from "../../utils";

export const sendUniqueCode = (code: string) => {
  if (!navigator.onLine) {
    //check if user is online or not
    Alert(3, "PLEASE CHECK INTERNET");
    return;
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.post(`/pharmacy-device`, {
        pharmacy_unique_code: code,
        device_id: getDeviceIdFromLS(),
      });
      Alert(1, response?.data?.messages[0]);
      resolve(response);
    } catch (error) {
      // Alert(2, error?.response?.data?.messages[0]);
      Alert(2, "Entered code is not correct. Please enter correct unique code.");
      reject(error);
    }
  });
};

export const sendOtp = (code: string, otp: number) => {
  if (!navigator.onLine) {
    //check if user is online or not
    Alert(3, "PLEASE CHECK INTERNET");
    return;
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.patch(`/pharmacy-device`, {
        pharmacy_unique_code: code,
        device_id: getDeviceIdFromLS(),
        verification_code: otp,
      });
      Alert(1, response?.data?.messages[0]);
      resolve(response);
    } catch (error) {
      Alert(2, error?.response?.data?.messages[0]);
      reject(error);
    }
  });
};


