//@ts-nocheck
import { createReducer } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface ActionWithPayload extends Action {
  type: any,
  payload: any
}

export const patientProfileInitialState: any = {  
  age: "",
  balanceClaim: "",
  beneficiaryId: "",
  benefits: [],
  cardList: [],
  cardType: "",
  employeeName: "",
  employeeNumber: "",
  entityId: "",
  familyMemberPhoneNumber: "",
  familyPhoneNumber: "",
  familyMemberPhoneChecked: false,
  finalEntityId: "",
  gender: "",
  insuranceData: {},
  insuranceMemberOptionSelect: false,
  insuranceMemberDependents: [],
  isPrincipal: false,
  patientCountryCode: "",
  patientDoB: "",
  patientFamilyMemberData: [],
  patientInsuranceMember: [],
  patientInsuranceMemberDataList: [],
  consultationFees: "",
  patientInsurance: "",
  patientName: "",
  phoneNumber: "",
  policyStatus: "",
  principalEntityId: "",
  principalPhoneNumber: "",
  search: "",
  showSearchMemberNo: false,
  insurance_member_dependents:[],
  fundedBy:""
};

const patientProfileReducer = createReducer(patientProfileInitialState, (builder) => {
  builder
    .addCase('SET_PATIENT_PROFILE', (state, action) => {
      return {
        ...state,
        ...action.payload
      } 
    })
    .addCase('SET_CARD_LIST', (state, action) => {
      return {
        ...state,
        cardList: action.payload
      }
    })
})

export const patientProfile = (state: RootState) => state.patientProfile;

export default patientProfileReducer;
