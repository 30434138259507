export const commonAction = {
	IS_API_LOADING: "IS_API_LOADING",
	SET_API_DATA: "SET_API_DATA",
	SET_API_ERROR: "SET_API_ERROR",
};

export const relatedAction = {
	IS_RQST_VERIFICATION_CODE_API_LOADING:
		"IS_RQST_VERIFICATION_CODE_API_LOADING",
	SET_RQST_VERIFICATION_CODE_API_DATA: "SET_RQST_VERIFICATION_CODE_API_DATA",
	SET_RQST_VERIFICATION_CODE_API_ERROR: "SET_RQST_VERIFICATION_CODE_API_ERROR",
	IS_VERIFY_LAB_RQST_API_LOADING: "IS_VERIFY_LAB_RQST_API_LOADING",
	SET_VERIFY_LAB_RQST_API_DATA: "SET_VERIFY_LAB_RQST_API_DATA",
	SET_VERIFY_LAB_RQST_API_ERROR: "SET_VERIFY_LAB_RQST_API_ERROR",
	RESET_VERIFY_LAB_RQST_API_ERROR: "RESET_VERIFY_LAB_RQST_API_ERROR",
	IS_DELETE_LAB_RQST_API_LOADING: "IS_DELETE_LAB_RQST_API_LOADING",
	SET_DELETE_LAB_RQST_API_DATA: "SET_DELETE_LAB_RQST_API_DATA",
	SET_DELETE_LAB_RQST_API_ERROR: "SET_DELETE_LAB_RQST_API_ERROR",
	RESET_DECLINE_LAB_RQST_API_DATA: "RESET_DECLINE_LAB_RQST_API_DATA",
	RESET_LAB_RQST_API_DATA: "RESET_LAB_RQST_API_DATA",
	RESET_VERIFY_LAB_DELETE_API_ERR: "RESET_VERIFY_LAB_DELETE_API_ERR",
	IS_UPDATE_LAB_REPORT_API_LOADING: "IS_UPDATE_LAB_REPORT_API_LOADING",
	SET_UPDATE_LAB_REPORT_API_DATA: "SET_UPDATE_LAB_REPORT_API_DATA",
	SET_UPDATE_LAB_REPORT_API_ERROR: "SET_UPDATE_LAB_REPORT_API_ERROR",
	RESET_UPDATE_LAB_REPORT_API_DATA: "RESET_UPDATE_LAB_REPORT_API_DATA",
	IS_USER_EMAIL_API_LOADING: "IS_USER_EMAIL_API_LOADING",
	SET_USER_EMAIL_API_DATA: "SET_USER_EMAIL_API_DATA",
	SET_USER_EMAIL_API_ERROR: "SET_USER_EMAIL_API_ERROR",
	IS_DOCTOR_EMAIL_API_LOADING: "IS_DOCTOR_EMAIL_API_LOADING",
	RESET_USER_EMAIL_API_DATA: "RESET_USER_EMAIL_API_DATA",
	RESET_BRANCH_API_DATA: "RESET_BRANCH_API_DATA",
	RESET_USER_API_DATA: "RESET_USER_API_DATA",
	RESET_MYPROFILE_API_DATA: "RESET_MYPROFILE_API_DATA",
	IS_UPDATE_PAYMENT_INSUR_API_LOADING: "IS_UPDATE_PAYMENT_INSUR_API_LOADING",
	SET_UPDATE_PAYMENT_INSUR_API_DATA: "SET_UPDATE_PAYMENT_INSUR_API_DATA",
	SET_UPDATE_PAYMENT_INSUR_API_ERROR: "SET_UPDATE_PAYMENT_INSUR_API_ERROR",
	CLEAR_PROFILE_API_ERROR: "CLEAR_PROFILE_API_ERROR",
	CLEAR_DATA_ON_UPDATE_INSURANCE_PAYMENT_OPTION:
		"CLEAR_DATA_ON_UPDATE_INSURANCE_PAYMENT_OPTION",
	OTHER__API_DATA: "OTHER__API_DATA",
	ADD_CUSTOMER_DATA: "ADD_CUSTOMER_DATA",
	ADD_CLINIC_DATA: "ADD_CLINIC_DATA",
	RESET_APPOINTMENT_DETAIL: "RESET_APPOINTMENT_DETAIL",
	ADD_CLINIC_DATA_API_LOADING: "ADD_CLINIC_DATA_API_LOADING",
	RESET_APPOINTMENT_SLOTS: "RESET_APPOINTMENT_SLOTS",
	SELECTED_SLOT: "SELECTED_SLOT",
	IS_RESCHEDULE_API_LOADING: "IS_RESCHEDULE_API_LOADING",
	IS_DECLINE_APPOINTMENT_API_LOADING: "IS_DECLINE_APPOINTMENT_API_LOADING",
	IS_APPROVE_APPOINTMENT_API_LOADING: "IS_APPROVE_APPOINTMENT_API_LOADING",
	IS_ADD_FAQ_API_LOADING: "IS_ADD_FAQ_API_LOADING",
	IS_WEBSITE_UPDATE_API_LOADING: "IS_WEBSITE_UPDATE_API_LOADING",
	IS_DELETE_FAQ_API_LOADING: "IS_DELETE_FAQ_API_LOADING",
	IS_EDIT_FAQ_API_LOADING: "IS_EDIT_FAQ_API_LOADING",
	RESET_CONTACT_US_DATA: "RESET_CONTACT_US_DATA",
	SET_SPECIFIC_API_DATA: "SET_SPECIFIC_API_DATA",
	IS_API_LOADING_FOR_LEFT_OVER_DATA: "IS_API_LOADING_FOR_LEFT_OVER_DATA",
	IS_AVAILABLE_API_LOADING: "IS_AVAILABLE_API_LOADING",
	RESET_INSURANCE_DATA: "RESET_INSURANCE_DATA",
	IS_BOOK_APMNT_API_LOADING: "IS_BOOK_APMNT_API_LOADING",
	RESET_SLOTS_DATA: "RESET_SLOTS_DATA",
	SET_WEBSITE_SLOTS_DATA: "SET_WEBSITE_SLOTS_DATA",
	APPMNT_BOOKED: "APPMNT_BOOKED",
	IS_SAVE_DOCTOR_NOTES_API_LOADING: "IS_SAVE_DOCTOR_NOTES_API_LOADING",
	SAVE_SOCKET_REF: "SAVE_SOCKET_REF",
	CLEAR_SOCKET_REF: "CLEAR_SOCKET_REF",
	APPMNT_BOOKED_DATA: "APPMNT_BOOKED_DATA",
	APPMNT_BOOKING_FAILED: "APPMNT_BOOKING_FAILED",
	CLEAR_APPMNT_BOOK_ERR: "CLEAR_APPMNT_BOOK_ERR",
	RESET_CLAIM_DETAIL: "RESET_CLAIM_DETAIL",
	APPMNT_BOOKED_DECLINED: "APPMNT_BOOKED_DECLINED",
	APPMNT_BOOKING_FAILED_WHEN_CITY: "APPMNT_BOOKING_FAILED_WHEN_CITY",
	RESET_PRE_AUTH_DETAIL_DATA: "RESET_PRE_AUTH_DETAIL_DATA",
	RESET_INITIATE_PAYMENT_API_DATA: "RESET_INITIATE_PAYMENT_API_DATA",
	CLEAR_INITIATE_PAYMENT_API_ERROR: "CLEAR_INITIATE_PAYMENT_API_ERROR",
	DIARY_REFRESHER: "DIARY_REFRESHER",
	NOTIFICATION_COUNT: "NOTIFICATION_COUNT",
};
export const COUNTRYREDUCERNAME = "COUNTRY";
export const CLAIMREDUCERNAME = "CLAIM";
export const SOCKETREDUCERNAME = "SOCKET";
export const WEBSITESOCKETREDUCERNAME = "WEBSITE_SOCKET";
export const WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME = "WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME";
export const MEETINGREDUCERNAME = "MEETING";
export const WEBSITEMEETINGREDUCERNAME = "WEBSITEMEETINGREDUCERNAME"
export const APPOINTMENTREDUCERNAME = "APPOINTMENTREDUCERNAME";
export const PREAUTHREDUCERNAME = "PREAUTH";
export const LOGOUT = "LOGOUT";
export const APPOINTMENTDETAILREDUCERNAME = "APPOINTMENTDETAILREDUCERNAME";
export const APPOINTMENTSLOTSREDUCERNAME = "APPOINTMENTSLOTSREDUCERNAME";
export const DIARYREFRESHERREDUCER = "DIARYREFRESHERREDUCER";
export const videoCallNotifier = {
	VIDEO_CALL_NOTIFIER_PATIENT: "VIDEO_CALL_NOTIFIER_PATIENT",
	VIDEO_CALL_NOTIFIER_DOCTOR: "VIDEO_CALL_NOTIFIER_DOCTOR",
	VIDEO_CALL_END_CALL_LISTNER: "VIDEO_CALL_END_CALL_LISTNER",
	PATIENT_INITIATED_CALL_LISTNER: "PATIENT_INITIATED_CALL_LISTNER",
	SOCKET_END_CALL_LISTEN: "SOCKET_END_CALL_LISTEN",
	PATIENT_IS_NOT_ONLINE: "PATIENT_IS_NOT_ONLINE",
	DOCTOR_IS_NOT_ONLINE: "DOCTOR_IS_NOT_ONLINE",
	PATIENT_CALL_ENDED_SUCCESS: "PATIENT_CALL_ENDED_SUCCESS",
	PATIENT_CALL_DURATION_ENDED_SUCCESS: "PATIENT_CALL_DURATION_ENDED_SUCCESS",
	DOCTOR_NOT_REFRESHED: "DOCTOR_NOT_REFRESHED",
	UNAVAILABLE_IS_ACTIVE_DR: "UNAVAILABLE_IS_ACTIVE_DR",
};
export const commonAction2 = {
	IS_API_LOADING: "IS_API_LOADING2",
	SET_API_DATA: "SET_API_DATA2",
	SET_API_ERROR: "SET_API_ERROR2",
};
