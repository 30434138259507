import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { makeStyles, createStyles } from "@mui/styles";
import OtpInput from "react-otp-input";
import { Alert, setPharmacyDetailsInLS } from '../../../utils';
import theme from '../../../theme/theme';
import { sendOtp, sendUniqueCode } from '../uniqueCode.api';

const useStyles = makeStyles(() =>
  createStyles({
    layout: {
        margin: "50px",
        padding: "20px",
        backgroundColor: "white",
        borderRadius: "5px",
        boxShadow: " 5px 5px 8px 0 rgba(0, 0, 0, 0.1)"
    },
    otpBox: {
        width: "280px", 
        margin: "20px auto",
      display: 'flex',
      justifyContent: 'space-between'
    },
    otpTextField: {
      width: "100% !important",
      height: "50px",
      borderRadius: "10px",
      backgroundColor: "rgba(23,143,201,0.05)",
      fontFamily: `"Rubik", sans-serif`,
      fontSize: "36px",
      lineHeight: "0.89",
      color: "var(--blue-grey)",
      border: "0px",
      margin: '10px'
    },
    otpContainer: {
      display: "flex",
      justifyContent: "space-between",
      margin: '-10px'
    },
    removeFocus: {
      outline: "none",
    },
  })
);

function OtpVerification(props: any) {
    const classes = useStyles({theme});
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");

    const inputCapture = (value: any) => {
        setOtp(value);
        // if (!Utils.constants.spaceRegex.test(value)) {
        //    Alert(3, "BLANK_SPACE");
        //    return false
        // }
    };

    const handleSendOTP = (event: any) => {
        event.preventDefault();

        if (otp.length === 4) {
            console.log("otp", otp)
            sendOtp(props.uniqueCode, Number(otp))
            .then((res: any) => {
              let { data } = res;
              setPharmacyDetailsInLS(data.pharmacy_id, data.pharmacy_name, data.pharmacy_avatar);
              window.location.reload();
            })
            .catch((err: any) => {
              let { data } = err.response;
            })
        } else {
          Alert(2, "Please enter OTP correctly!");
        }
    };

  return (
    <div className="mb-20 center">
        <Typography variant='h5' className="mb-15">OTP Verification</Typography>
        <Typography>
          Please enter the 4 digit OTP sent to your registered phone number
        </Typography>

        <Box className={classes.otpBox}>
            <OtpInput
                value={otp}
                containerStyle={classes.otpContainer}
                inputStyle={classes.otpTextField}
                onChange={inputCapture}
                numInputs={4}
                focusStyle={classes.removeFocus}
                shouldAutoFocus={true}
                isInputNum={true}
            />
        </Box>

        <div className="flex-center mt-20 mb-20">
          <button
            className='btn btn-primary'
            onClick={handleSendOTP}
          >
            Verify OTP
          </button>
        </div>

        <div className="flex-center mt-20">
            <Typography>
                Did not receive OTP?
            </Typography>
            <button
                // disabled={enableResend}
                className='btn'
                style={{ border: 'none', boxShadow: 'none' }}
                onClick={() => sendUniqueCode(props.uniqueCode)}
            >
                {"Resend"}
            </button>
        </div>
      </div>
  )
}

export default OtpVerification
