import { relatedAction, WEBSITESOCKETREDUCERNAME } from "../MeetingChime/actionConstant"

const initialState = {
  socketRef: null,
}

export const WebsiteSocketReducer = (state = initialState, action) => {
  
  switch (action.type) {
    
    case `${WEBSITESOCKETREDUCERNAME}_${relatedAction.SAVE_SOCKET_REF}`:
      return {
        ...state,
        socketRef: action.payload,
      }

    case `${WEBSITESOCKETREDUCERNAME}_${relatedAction.CLEAR_SOCKET_REF}`:
      return {
        ...state,
        socketRef: null,
      }

    default:
      return { ...state }
  }
}
