import React from "react";
// import { roles, getRoleIdInLS } from "../../../utils/videoCall";
import { useSelector } from "react-redux";

function DoctorCancelMeeting(props) {
	const {
		cancelCallFromDoctorSide,
		rejoinCancelCallFromDoctorSide,
		role,
		isInsurance,
		claimAndPrescriptionHandler,
		isCallFinished,
		isClaimRXDone,
	} = props;
	// const { apiData } = useSelector((state) => state?.websiteHome);

	return (
		<div className="popup-cover">
			<div className="call-confirm-doctor-popup popup-ui">
				<div
					className="popup-header"
					style={
						8==8
							? {
									backgroundImage:
										"linear-gradient(to right, #152567, #53c392)",
							  }
							: ""
						// : { backgroundColor: apiData?.body?.body_data?.header_bg_color }
					}
				>
					<h3 className="text-blue bold center">Please Confirm</h3>
				</div>
				<div className="popup-body">
					<p className="center fs22">
						{role == 4
							? "Are you sure you want to end the call?"
							: isInsurance == 1
							? isClaimRXDone == 1
								? "End Call and View E-Claim?"
								: "End Call and Create an E-Claim?"
							: isClaimRXDone == 1 && isCallFinished == 1
							? "End Call and View Prescription?"
							: "End Call and Write Prescription?"}
					</p>
				</div>
				<div className="popup-footer">
					<button
						className="btn btn-delete fs22"
						onClick={cancelCallFromDoctorSide}
					>
						End Call
					</button>
					{role == 8 && (
						<button
							className="btn btn-simple fs22"
							onClick={claimAndPrescriptionHandler}
						>
							{isInsurance == 1
								? isClaimRXDone == 1
									? "View E-Claim"
									: "Create E-Claim"
								: isClaimRXDone == 1 && isCallFinished == 1
								? "View prescription"
								: "Write prescription"}
						</button>
					)}
					<button
						className="btn btn-primary fs22"
						onClick={rejoinCancelCallFromDoctorSide}
					>
						Rejoin
					</button>
				</div>
			</div>
		</div>
	);
}

export default DoctorCancelMeeting;
