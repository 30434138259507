import {
	commonAction,
	commonAction2,
	WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME,
	relatedAction,
} from "../MeetingChime/actionConstant";

const initialState = {
	appointmentDetailApiData: null,
	isApiLoading: false,
	isApiLoading2: false,
	apiError: null,
	appointmentBookApiFailedWhenCityDiff: "",
};

export const AppointmentsDetailReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME}_${commonAction.IS_API_LOADING}`:
			return {
				...state,
				isApiLoading: action.payload,
			};

		case `${WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME}_${commonAction2.IS_API_LOADING}`:
			return {
				...state,
				isApiLoading2: action.payload,
			};

		case `${WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME}_${commonAction.SET_API_DATA}`:
			return {
				...state,
				appointmentDetailApiData: action.payload,
				isApiLoading: false,
			};

		case `${WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME}_${commonAction.SET_API_ERROR}`:
			
			return {
				...state,
				apiError: action.payload,
				isApiLoading: false,
			};

		case `${WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME}_${commonAction.RESET_REDUCER}`:
			return {
				...initialState,
			};
		case `${WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME}_${relatedAction.DIFF_CITY_SCHEDULE}`:
			return {
				...state,
				appointmentBookApiFailedWhenCityDiff: action.payload,
			};
		default:
			return { ...state };
	}
};
