export const SignalTypeAppWentToBackground = 4;
export const CAMERA_ON = 7;
export const CAMERA_OFF = 6;
export const SignalTypeAppResumedFromBackground = 5;
export const DATA_MESSAGE_TOPIC = "chat";
export const DATA_MESSAGE_LIFETIME_MS = 300000;
export const END_CALL_BOTH_CONNECTED = 10;
export const MOBILE_DETECTED = 11;
export const DESKTOP_DETECTED = 12;


export function SecondsToHMSConvertor(time:any) {
	// Hours, minutes and seconds
	const hrs = ~~(time / 3600);
	const mins = ~~((time % 3600) / 60);
	const secs = ~~time % 60;

	// Output like "1:01" or "4:03:59" or "123:03:59"
	let ret = "";
	if (hrs > 0) {
		ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
	}
	ret += "" + mins + ":" + (secs < 10 ? "0" : "");
	ret += "" + secs;
	//   return ret
	return { hour: hrs, minutes: mins, seconds: secs };
}