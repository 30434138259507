import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Transition from "react-transition-group/Transition";
import { io } from "socket.io-client";
import { getPhoneId, getTabId } from "../../utils/Common.Function";
import {
  meetingRejectedAction,
  saveWebSocketRefAction,
} from "../MeetingChime/actions";
import { useNavigate } from "react-router-dom";
import doctopPlaceHolder from "../../assets/image/doctor_placeholder.svg";
import { batch, useDispatch } from "react-redux";
import { saveWebsiteWebSocketRefAction } from "../../components/MeetingChime/actions";
import { Env } from "../../constants";
import { Socket } from "../../constants/Socket";
// import { withRouter } from "react-router";
import ringtone from "../../assets/ringtones/forChromeAndOthers.wav";
import CallKit from "../callKit";
import { videoCallNotifier } from "../MeetingChime/actionConstant";
import { store } from "../../app/store";
import useAudio from "../MeetingChime/components/useAudio";
import { toastIdsConstant } from "../../utils/toastConstant";
// import { clearAppointmentDetailData } from "../redux/actions/appointmentAction";
const getUserType = () => 1;
const ZoomInAndOut = ({ children, position, ...props }) => (
  <Transition
    {...props}
    timeout={800}
    onEnter={(node) => node.classList.add("zoomIn", "animate")}
    onExit={(node) => {
      node.classList.remove("zoomIn", "animate");
      node.classList.add("zoomOut", "animate");
    }}
  >
    {children}
  </Transition>
);
// Currently in Use for Meeting Socket (Patient & Doctor) #VideoCall
const getAccessTokenFromLS = () => "asdhajsda";
const getWebsiteAccessTokenFromLS = () => "sdas";
function MeetingNotificationWithSocket(props) {
  const navigate = useNavigate();
  const ws = useRef(null);
  const [accessToken, setAccesTocken] = useState(null);
  const callingRes = useRef(null);
  const ringtoneAudioNotification = useRef(null);
  let toastId = useRef(null);
  // const { history } = props;
  const [isCalling, setIsCalling] = useState(false);
  const dispatch = useDispatch();
  const [play, stop] = useAudio(ringtone);

  console.log("tabid socket", getTabId());

  useEffect(() => {
    setTimeout(() => {
      console.log("current connecttosocket call", !ws.current?.connected);
      if (localStorage.getItem("accessToken")) connectToSocket();
    }, 1500);

    return () => {
      ws?.current?.off(Socket.START_CALL, notificationStartListner);
      ws?.current?.off(Socket.CANCEL_CALL, notificationCancelListner);
      ws?.current?.off(Socket.END_CALL, notificationEndCallListner);
      ws?.current?.off(Socket.ACCEPT_CALL, notificationAcceptCallListner);
      ws?.current?.off(Socket.REJECT_CALL, notificationRejectCallListner);
      ws?.current?.off(Socket.BUSY_CALL, notificationBusyCallListner);
    };
  }, [navigator.onLine,accessToken]);

  const connectToSocket = () => {
    ws.current = io(
      `${Env.SOCKET_URL}?authorization=${localStorage.getItem(
        "accessToken"
      )}&userType=1&phone_id=${getPhoneId()}&tab_id=${getTabId()}`,
      { transports: ["websocket"] }
    );
    console.log(getTabId(), "getTabId",);

    console.log("current socket out", ws?.current);
    ws.current.on("connect", () => {
      console.log("current socket in", ws?.current);
      console.log(ws.current.connected, "*******connected******");
      if (ws.current.connected) {
        // props.setSocketRef(ws.current)
        // dispatch({
        //   type: "WEBSITE_SOCKET_SAVE_SOCKET_REF",
        //   payload: ws?.current,
        // });
        dispatch(saveWebSocketRefAction(ws?.current));
        dispatch(saveWebsiteWebSocketRefAction(ws?.current));
      }
      //

      ws.current.on(Socket.START_CALL, notificationStartListner);
      ws.current.on(Socket.CANCEL_CALL, notificationCancelListner);
      ws.current.on(Socket.END_CALL, notificationEndCallListner);
      ws.current.on(Socket.ACCEPT_CALL, notificationAcceptCallListner);
      ws.current.on(Socket.REJECT_CALL, notificationRejectCallListner);
      ws.current.on(Socket.BUSY_CALL, notificationBusyCallListner);
    });

    ws.current.on("disconnect", (reason) => {
      console.log("current disconnect");
      setTimeout(() => {
        console.log("current disconnect interval");

        // if (navigator?.onLine) connectToSocket();
      }, 1000);
      console.log(
        ws.current.connected,
        `*******${reason}****** will attempt to reconnect in 1sec`
      );
    });
    ws.current.on("connect_error", (err) => {
      console.log(err.message, "*******connect_error******");
      ws.current.close();
    });
  };

  const notificationAcceptCallListner = (res) => {
    console.log("Listener, notificationAcceptCallListner", res);
    // if (toastId) {
    // toast.dismiss(toastId);
    stopRingtone();
    setIsCalling(false);
    // }
    batch(() => {
      dispatch({
        type: videoCallNotifier.PATIENT_INITIATED_CALL_LISTNER,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_DOCTOR,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_PATIENT,
        payload: false,
      });
    });
    if (res.code == 200) {
      // toast.dismiss(toastId);
      // stopRingtone();
    } else {
      toast.warn("Some Issue in Accepting Call, please try again.", {
        className: "toast-warn",
      });
    }
  };

  const notificationBusyCallListner = (res) => {
    console.log("Listener, notificationBusyCallListner", res);
    if (!toast.isActive(toastIdsConstant.busycall)) {
      toastIdsConstant.busycall = toast.warn(
        `${res.result.fromUserName} is busy on another call, please try again later.`,
        {
          toastId: toastIdsConstant.busycall,
          className: "toast-warn",
          preventDuplicates: true,
          preventOpenDuplicates: true,
        }
      );
    }
    batch(() => {
      dispatch({
        type: videoCallNotifier.PATIENT_INITIATED_CALL_LISTNER,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_DOCTOR,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_PATIENT,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.SOCKET_END_CALL_LISTEN,
        payload: false,
      });
    });
    if (res.code == 200) {
      // toast.dismiss(toastId);
      // stopRingtone();
    }
  };

  const notificationRejectCallListner = (res) => {
    console.log("Listener, notificationRejectCallListner", res);
    // if (toastId) {
    //   toast.dismiss(toastId);
    stopRingtone();
    setIsCalling(false);
    // }
    batch(() => {
      dispatch({
        type: videoCallNotifier.PATIENT_INITIATED_CALL_LISTNER,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_DOCTOR,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_PATIENT,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.SOCKET_END_CALL_LISTEN,
        payload: false,
      });
    });
    if (res.code == 200) {
      // toast.dismiss(toastId);
      // stopRingtone();
    } else {
      toast.warn("Some Issue in Rejecting Call, please try again.", {
        className: "toast-warn",
      });
    }
  };

  const cancelCallHandler = () => {
    setIsCalling(false);
    // if (toastId) {
    // console.log(toastId, "toastId");
    // document.getElementById(toastId).style.display = "none";
    // toast.dismiss(toastId);
    stopRingtone();
    // }
    batch(() => {
      dispatch({
        type: videoCallNotifier.PATIENT_INITIATED_CALL_LISTNER,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_DOCTOR,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_PATIENT,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.SOCKET_END_CALL_LISTEN,
        payload: false,
      });
    });
    meetingRejectedAction(
      {
        call_rejected: 1,
        event_id: callingRes.current.result.eventId,
        device_push_token: "abc",
      },
      getUserType() == 1 ? "patient" : "doctor"
    );
    if (getUserType() == 1) {
      ws.current.emit(Socket.REJECT_CALL, {
        eventId: callingRes.current.result.eventId,
        accessToken: getWebsiteAccessTokenFromLS(),
      });
      ws.current.emit(Socket.CANCEL_CALL, {
        eventId: callingRes.current.result.eventId,
        toUserId: callingRes.current.result.fromUserId,
        toUserType: 2,
      });
    } else {
      ws.current.emit(Socket.REJECT_CALL, {
        eventId: callingRes.current.result.eventId,
        accessToken: getAccessTokenFromLS(),
      });
      ws.current.emit(Socket.CANCEL_CALL, {
        eventId: callingRes.current.result.eventId,
        toUserId: callingRes.current.result.fromUserId,
        toUserType: 1,
      });
    }
  };
  // console.log("res inner", patientInitiatedCall);

  const notificationStartListner = (res) => {
    console.log("Listener, notificationStartListner", res);
    if (getUserType() == 1 && !getWebsiteAccessTokenFromLS()) {
      ws.current.disconnect();
      return;
    }
    const { href } = window.location;

    const {
      patientInitiatedCall,
      socketEndCallListener,
      patientIsNotOnline,
      patientCallEndedSuccess,
      patientCallDurationEndedSuccess,
      unavailablePopupIsActiveDr,
    } = store.getState().videoCallNotifierReducer;

    if (
      getUserType() == 2 &&
      href.indexOf("appointments/appointment-detail") > -1 &&
      !href.includes(res.result.eventId)
    ) {
      navigate(`/appointments`);
    }

    if (
      getUserType() == 2 &&
      (href.indexOf("/appointment/prescriptions/create/") > -1 ||
        patientCallEndedSuccess)
    ) {
      //   dispatch(clearAppointmentDetailData());
      navigate(`/doctor`);
      dispatch({
        type: videoCallNotifier.PATIENT_CALL_ENDED_SUCCESS,
        payload: false,
      });
    }

    if (
      unavailablePopupIsActiveDr &&
      getUserType() == 2 &&
      href.indexOf(res.result.eventId) > -1
    ) {
      navigate(`/appointments`);
      dispatch({
        type: videoCallNotifier.UNAVAILABLE_IS_ACTIVE_DR,
        payload: false,
      });
    }

    if (patientCallDurationEndedSuccess && getUserType() == 2) {
      navigate(`/appointments`);
    }

    if (patientIsNotOnline && getUserType() == 2) {
      dispatch({
        type: videoCallNotifier.PATIENT_IS_NOT_ONLINE,
        payload: false,
      });
      navigate(`/appointments/appointment-detail/${res.result.eventId}`);
    }

    if (
      href.indexOf(`${res.result.eventId}`) > -1 &&
      href.indexOf("?join=true") > -1 &&
      !socketEndCallListener &&
      getUserType() == 2 &&
      !patientCallDurationEndedSuccess
    ) {
      //same call is trying to connect, don't show popup.
      ws.current.emit(Socket.ACCEPT_CALL, {
        eventId: res.result.eventId,
        accessToken: getAccessTokenFromLS(),
      });
      return;
    }
    if (
      patientInitiatedCall &&
      getUserType() == 2 &&
      href.indexOf(`${res.result.eventId}`) == -1
    ) {
      ws.current.emit(Socket.BUSY_CALL, {
        eventId: res.result.eventId,
        toUserId: res.result.fromUserId,
        toUserType: 1,
        accessToken: getAccessTokenFromLS(),
      });
      console.log("socket emit, busy call ");
      return;
    }
    if (getUserType() == 2) {
      batch(() => {
        dispatch({
          type: videoCallNotifier.PATIENT_INITIATED_CALL_LISTNER,
          payload: true,
        });
        dispatch({
          type: videoCallNotifier.VIDEO_CALL_NOTIFIER_PATIENT,
          payload: true,
        });
        dispatch({
          type: videoCallNotifier.SOCKET_END_CALL_LISTEN,
          payload: false,
        });
        dispatch({
          type: videoCallNotifier.PATIENT_CALL_DURATION_ENDED_SUCCESS,
          payload: false,
        });
      });
    }
    if (getUserType() == 1) {
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_DOCTOR,
        payload: true,
      });
      dispatch({
        type: videoCallNotifier.SOCKET_END_CALL_LISTEN,
        payload: false,
      });
    }
    if (res) {
      callingRes.current = res;
    }
    if (res.code == 200) {
      play();
      console.log(res.result, "res.result");
      setIsCalling(true);
      // setIsCalling(true);
      // if (!toast.isActive(toastId)) {
      //   return (toastId = toast.warn(
      //     <div className="incoming-call-toaster">
      //       <h2 className="bold center mb-10">Consultation Call</h2>
      //       <p className="center bold">{`${res.result.fromUserName} is calling you...`}</p>
      //       <div
      //         style={{
      //           width: 55,
      //           height: 55,
      //           borderRadius: "50%",
      //           overflow: "hidden",
      //           border: "1px solid #d3d3d3",
      //           margin: "20px auto",
      //         }}
      //       >
      //         <img
      //           src={
      //             res?.result?.eventData?.user_avatar
      //               ? `${Env.BASE_URL + res?.result?.eventData?.user_avatar}`
      //               : doctopPlaceHolder
      //           }
      //           style={{
      //             width: "100%",
      //             height: "100%",
      //             objectFit: "cover",
      //           }}
      //         />
      //       </div>
      //       <div className="btn-cover">
      //         <button
      //           className="btn btn-delete mr-20"
      //           onClick={cancelCallHandler}
      //         >
      //           Decline
      //         </button>
      //         <button
      //           className="btn btn-primary"
      //           onClick={() => acceptCallHandler(res)}
      //         >
      //           Accept
      //         </button>
      //       </div>
      //     </div>,
      //     {
      //       toastId: toastId,
      //       className: "toaster-for-incoming-call",
      //       // autoClose: 120000,
      //       transition: ZoomInAndOut,
      //       closeButton: false,
      //     }
      //   ));
      // }
      return;
    } else {
      toast.warn("Some Issue in Initiating Call, please try again.", {
        className: "toast-warn",
      });
    }
  };
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setAccesTocken(localStorage.getItem("accessToken"));
    }
  }, [localStorage.getItem("accessToken")]);
  const acceptCallHandler = (res) => {
    setIsCalling(false);
    // if (toastId) {
    // document.getElementById(toastId).style.display = "none";
    // toast.dismiss(toastId);
    stopRingtone();
    // }
    batch(() => {
      dispatch({
        type: videoCallNotifier.PATIENT_INITIATED_CALL_LISTNER,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_END_CALL_LISTNER,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.SOCKET_END_CALL_LISTEN,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_DOCTOR,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_PATIENT,
        payload: false,
      });
    });
    console.log("socket accept call", res);
    ws.current.emit(Socket.ACCEPT_CALL, {
      eventId: callingRes.current.result.eventId,
      accessToken:
        getUserType() == 1
          ? getWebsiteAccessTokenFromLS()
          : getAccessTokenFromLS(),
    });
    const isDoctor = getUserType() !== 1;
    if (isDoctor) {
      //if user is doctor then redirect to doctor page
      if (
        window.location.href.indexOf("/appointments/appointment-detail") > -1 &&
        window.location.href.indexOf("?join=true") > 1
      ) {
        setTimeout(() => {
          
          window.location.reload();
        }, 1000);
      }
      navigate(
        `/appointments/appointment-detail/meeting-chime/${res.result.eventId}/meeting?join=true`
      );
    } else {
      //else redirect to user call page.
      if (
        window.location.href.indexOf("/chime/detail") > -1 &&
        window.location.href.indexOf("?join=true") > 1
      ) {
        setTimeout(() => {
          
          window.location.reload();
        }, 1000);
      }
      navigate(
        `/appointments/appointment-detail/meeting-chime/${res.result.eventId}/meeting?join=true`
      );
    }
  };

  const notificationCancelListner = (args) => {
    console.log("Listener, notificationCancelListner", args);
    setIsCalling(false);
    // if (toastId) {
    // toast.dismiss(toastId);
    stopRingtone();
    // }
    if (window.location.href.indexOf("?join=true") > 1) {
      // When call accepted and ended by other user immediately
      const isDoctor = getUserType() !== 1;
      if (isDoctor) {
        navigate(`/appointments/appointment-detail/${args.result.eventId}`);
      } else {
        navigate(`/doctor`);
      }
    }
    batch(() => {
      dispatch({
        type: videoCallNotifier.PATIENT_INITIATED_CALL_LISTNER,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_DOCTOR,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_PATIENT,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.SOCKET_END_CALL_LISTEN,
        payload: false,
      });
    });
    if (args.code == 200) {
      // stopRingtone();
    } else {
      toast.warn("Some Issue in Cancelling Call, please try again.", {
        className: "toast-warn",
      });
    }
  };

  const notificationEndCallListner = (args) => {
    console.log("Listener, notificationEndCallListner", args);
    if (
      window.location.href.indexOf("/chime/detail") > -1 ||
      window.location.href.indexOf(
        "/appointments/appointment-detail/meeting-chime"
      ) > -1
    ) {
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_END_CALL_LISTNER,
        payload: true,
      });
    }
    batch(() => {
      dispatch({
        type: videoCallNotifier.SOCKET_END_CALL_LISTEN,
        payload: true,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_DOCTOR,
        payload: false,
      });
      dispatch({
        type: videoCallNotifier.VIDEO_CALL_NOTIFIER_PATIENT,
        payload: false,
      });
    });
    if (args.code == 200) {
      // toast.dismiss(toastId);
      // stopRingtone();
    } else {
      toast.warn("Some Issue in Ending Call, please try again.", {
        className: "toast-warn",
      });
    }
  };

  const stopRingtone = () => {
    stop();
  };

  return (
    <>
      {isCalling && (
        <CallKit
          res={callingRes.current}
          acceptCallHandler={acceptCallHandler}
          cancelCallHandler={cancelCallHandler}
        />
      )}
    </>
  );
}

export default MeetingNotificationWithSocket;
