import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState } from "../../app/store";
import { loaderEnd, loaderStart } from "../../features/loader/loader.slice";
import { doctorInitialState } from "../../redux-initial-state";
import { DoctorListDTO, GetDoctorQueryParmas } from "../../types";
import { Alert } from "../../utils";
import { getDoctorList } from "./doctor.api";

export const getDoctorListAsync = createAsyncThunk(
  "doctor/getDoctor",
  async (params: GetDoctorQueryParmas, { dispatch }) => {
    try {
      dispatch(loaderStart());
      const response: AxiosResponse<DoctorListDTO> = await getDoctorList(
        params
      );
      setTimeout(() => {
        dispatch(loaderEnd());
      }, 100);

      return response.data;
    } catch (error) {
      dispatch(loaderEnd());
      Alert(2, error.response);
    }
    return [];
  }
);

export const doctorSlice = createSlice({
  name: "doctor",
  initialState: doctorInitialState,
  reducers: {
    doctorOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    doctorLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    doctorSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    doctorSpecializationId: (state, action: PayloadAction<string>) => {
      state.specializationId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDoctorListAsync.fulfilled, (state, action) => {
      // console.log(action.payload,"ction.payload")
      //@ts-ignore
      state.data = action.payload?.body;
      //@ts-ignore
      state.count = action.payload?.count;
    });
  },
});

export const {
  doctorOffset,
  doctorSearch,
  doctorLimit,
  doctorSpecializationId,
} = doctorSlice.actions;

export const selectDoctor = (state: RootState) => state.doctor;

export default doctorSlice.reducer;
