import { Box, IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

type SearchTextfieldProps = {
  name: 'searchInput';
  placeholder: string;
  inputValue: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchSubmit: (event: React.SyntheticEvent) => void;
};

function SearchTextfield({
  name,
  placeholder,
  inputValue,
  handleSearchSubmit,
  handleSearchChange
}: SearchTextfieldProps) {
  return (
    <Box
      // className="search-box"
      sx={{
        borderRadius: 10,
        width: '100%',
        height: '90%',
        px: 1,
        display: 'flex',
        backgroundColor: 'common.white',
        boxShadow:"0 3px 10px 0 rgb(0 0 0 / 5%) !important"
      }}
      component="form"
      onSubmit={handleSearchSubmit}>
      <IconButton type="submit" aria-label="search" color="primary">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{
          flexBasis: '80%',
          // color: 'primary.main',
          font: (theme) => `normal ${theme.typography.fontWeightRegular} ${theme.spacing(1.6)} Roboto Sans, sans-serif`
        }}
        name={name}
        onChange={handleSearchChange}
        value={inputValue}
        placeholder={placeholder}
        inputProps={{ 'aria-label': `${placeholder}` }}
      />
    </Box>
  );
}

export default SearchTextfield;
