import React from "react";
// import { roles } from "../../../utility/roleConstant";
// import { getRoleIdInLS } from "../../../utility/util";
// import { useSelector } from "react-redux";

function MeeetingDuration(props) {
  const { finishConsultation, duration, isChangeMessage } = props;
  // const { apiData } = useSelector((state) => state?.websiteHome);

  return (
    <div className="popup-cover">
      <div className="calling-popup popup-ui">
        <div
          className="popup-header"
          style={{
            backgroundImage: "linear-gradient(to right, #152567, #53c392)",
            padding: 25,
          }}
        >
          <h3 className="text-blue bold center"> </h3>
        </div>
        <div className="popup-body">
          <p className="center fs22">
            {isChangeMessage ? (
              <>
                Doctor is writing your prescription.
                <br /> Delivery will be fulfilled by Livia Health to your
                office.
              </>
            ) : (
              <>
                Doctor is writing your prescription and will send to Pharmacy in
                few minutes.
                <br /> Please go to Pharmacy counter and collect your medicines.
              </>
            )}
          </p>
        </div>
        <div className="popup-footer">
          <button className="btn btn-primary fs22" onClick={finishConsultation}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}

export default MeeetingDuration;
