import { Box, Typography } from '@mui/material';

type IconTextProps = {
  icon: JSX.Element;
  text: string;
  isTextBold?: boolean;
  elips?: any
};

function IconText({ icon, text, isTextBold, elips = false }: IconTextProps) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ marginTop: '3px' }}>{icon}</Box>
      <Typography
        sx={elips ? {
          fontWeight: (theme) => (isTextBold ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium),
          fontSize: 20,
          ml: 0.5,
          width: "280px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap",
        } : {
          fontWeight: (theme) => (isTextBold ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium),
          fontSize: 20,
          ml: 0.5,
        }}>
        {text}
      </Typography>
    </Box>
  );
}

export default IconText;
