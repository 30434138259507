import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React from 'react';

type ButtonWrapperProps = {
  children: React.ReactNode;
} & ButtonProps;

const ButtonCustomize = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: theme.spacing(0.6),
  color: theme.palette.common.white,
  font: `normal ${theme.typography.fontWeightBold} ${theme.spacing(2)} Roboto Sans, sans-serif`,
  fontSize: '24px',
  textTransform: 'capitalize',
  padding: theme.spacing(1, 3),
  backgroundImage: 'var(--button-linear-gradient)'
}));

function ButtonWrapper({ children, ...otherProps }: ButtonWrapperProps) {
  return <ButtonCustomize {...otherProps}>{children}</ButtonCustomize>;
}

export default ButtonWrapper;
