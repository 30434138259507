import { Box, Card, CardActionArea, CardMedia, CardContent, Typography, CardActions } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import IconText from '../../components/icon-text/icon.text';
import { Env, Image } from '../../constants';
import ButtonWrapper from '../form-ui/button/button.wrapper';
import { Specialization } from '../../types';

type DoctorCardProps = {
  img: string;
  name: string;
  view: string;
  exp: string;
  qualification: string;
  speciality: Specialization[];
  onClick?: () => void;
  onClickConnect?: () => void;
  isButton?: boolean;
  elips?: boolean
};

function DoctorCard({ img, name, view, exp, qualification, speciality, isButton, onClick, onClickConnect, elips = false }: DoctorCardProps) {
  return (
    <div className="doctor-content">
      {/* // sx={{ maxWidth: 340, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}> */}
      <CardActionArea onClick={onClick}>
        <CardMedia
          component="img"
          height="160"
          image={img ? Env.BASE_URL + img : Image.DOCTOR_PLACEHOLDER}
          alt="green iguana"
          style={{width: "auto",margin:"0 auto"}}
        />
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5" color="primary" style={elips ? {
              maxWidth: "90%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
            } : {
            }}>
              {name}
            </Typography>
            <img src={Image.ACTIVE_ICON} alt="" width="20" />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <IconText icon={<RemoveRedEyeIcon color="primary" />} text={view} isTextBold />
            <IconText icon={<PersonIcon color="primary" />} text={`${exp} yrs`} isTextBold />
          </Box>
          <Box sx={{ my: 1 }}>
            <IconText icon={<SchoolIcon color="primary" />} text={qualification || 'N/A'} />
          </Box>
          <Box sx={{
            display: 'flex', alignItems: 'center', flexWrap: 'wrap',
          }}>
            <IconText
              icon={<WorkspacePremiumIcon color="primary" />}
              text={speciality?.length > 0 ? speciality.map((item) => item.name).join(', ') : 'N/A'}
              // sx={{ maxHeight: 60 }}
              elips={elips}
            />
          </Box>
        </CardContent>
      </CardActionArea>

      {isButton && (
        <CardActions>
          <ButtonWrapper
            variant="contained"
            sx={{ width: '100%' }}
            onClick={onClickConnect}
          >
            Connect Now
          </ButtonWrapper>
        </CardActions>
      )}
    </div>
  );
}

export default DoctorCard;
