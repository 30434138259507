import { relatedAction, SOCKETREDUCERNAME } from './actionConstant'

const initialState = {
  socketRef: null,
}

export const SocketReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case `${SOCKETREDUCERNAME}_${relatedAction.SAVE_SOCKET_REF}`:
      return {
        ...state,
        socketRef: action.payload,
      }

    case `${SOCKETREDUCERNAME}_${relatedAction.CLEAR_SOCKET_REF}`:
      return {
        ...state,
        socketRef: null,
      }

    default:
      return { ...state }
  }
}
