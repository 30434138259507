import Footer from '../footer/footer';
import GlobalLoader from '../../features/loader/loader';
import Header from '../header/header';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../style/layout.css"
// import MeetingNotificationWithSocket from "../socket/meeting.notification.with.socket"
type PrivateRouteProps = { children: React.ReactNode, isChime?: any };

function PrivateRoute({ children, isChime =false }: PrivateRouteProps) {
  const navigate = useNavigate()
  useEffect(()=>{
    if(isChime && !localStorage.getItem("accessToken")){
      navigate("/")
    }
  },[])
  return (
    <>
      <GlobalLoader />
      <Header />
      {/* <MeetingNotificationWithSocket /> */}
      <Box className="main-content">
        {children}
      </Box>       
      <Footer />     
    </>
  );
}

export default PrivateRoute;
