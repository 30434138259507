import { Container, Box, Grid, Typography, Paper } from '@mui/material';
import ButtonWrapper from '../../../components/form-ui/button/button.wrapper';
import DoctorCard from '../../../components/doctor-card/doctor.card';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getDoctorDetail } from './doctor.detail.api';
import { DoctorDetailDTO } from '../../../types';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from '../../../app/hooks';
import { loaderStart, loaderEnd } from '../../../features/loader/loader.slice';

function DoctorDetail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { doctorId } = useParams<{ doctorId: string }>();
  const [doctorDetail, setDoctorDetail] = useState({} as DoctorDetailDTO);

  useEffect(() => {
    if (doctorId) {
      dispatch(loaderStart());
      getDoctorDetail(doctorId)
        .then((res: AxiosResponse<DoctorDetailDTO>) => {
          setDoctorDetail(res?.data);
          dispatch(loaderEnd());
          dispatch({
            type: 'SET_DOCTOR_DETAILS',
            payload: res?.data
          });
        })
        .catch(() => {
          dispatch(loaderEnd());
        });
    }
  }, [doctorId]);

  const handleConnectNow = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    navigate("/verify-patient");
  }

  return (
    <Box sx={{ py: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'flex-end', background: "white", position: "fixed", width: "100%", top: 65, zIndex: 9, left: 0, px: 1, py: 1 }}>
        <ButtonWrapper variant="contained" sx={{ mr: 2 }} onClick={handleConnectNow}>Connect Now</ButtonWrapper>

      </Box>
      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'left', mb: 2 }}>
          <Typography variant="h4">Profile Detail</Typography>
        </Box>
        {doctorDetail?.id ? (
          <Paper sx={{ p: 2 }}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <DoctorCard
                  img={doctorDetail?.avatar}
                  name={doctorDetail?.name}
                  view={doctorDetail?.profile_views}
                  exp={doctorDetail?.experience_years}
                  qualification={doctorDetail?.qualification}
                  speciality={doctorDetail?.specializations}
                />
              </Grid>
              <Grid item xs={12} md={8} style={{ marginLeft: "30px", flexBasis: "60%" }}>
                <Typography variant="h5">About Doctor</Typography>
                <Typography color="primary" sx={{ my: 1, fontSize: 22 }}>
                  {doctorDetail?.description ? doctorDetail.description : "N/A"}
                </Typography>
                <Typography variant="h5">Clinic Name</Typography>
                <Typography color="primary" sx={{ my: 1, fontSize: 22 }}>
                  {doctorDetail?.doctor_hospitals?.length ? doctorDetail.doctor_hospitals.map((item)=>`${item}, `) : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          'Loading...'
        )}
      </Container>
    </Box>
  );
}

export default DoctorDetail;
