import { combineReducers } from "redux";
import counterReducer from "../features/counter/counterSlice";
import loaderReducer from "../features/loader/loader.slice";
import doctorDetailsReducer from "../pages/doctor/doctor-detail/doctorDetails.slice";
import doctorReducer from "../pages/doctor/doctor.slice";
import { SocketReducer } from "../components/MeetingChime/socketReducer";
import { videoCallNotifierReducer } from "../components/MeetingChime/videoCallNotifierReducer";
import { WebsiteSocketReducer } from "../components/socket/websiteSocket";
import { AppointmentsDetailReducer } from "../components/reducer/myAppointmentDetailReducer";
import { MeetingReducer } from "../components/reducer/meetingReducer";
import { WebsiteMeetingReducer } from "../components/reducer/websiteMeetingReducer";
import patientProfileReducer from "../pages/verifyPatient/patientProfile.slice";

const rootReducer = combineReducers({
  counter: counterReducer,
  loader: loaderReducer,
  doctor: doctorReducer,
  meeting: MeetingReducer,

  SocketReducer: SocketReducer,
  videoCallNotifierReducer: videoCallNotifierReducer,
  websiteAppointmentDetail: AppointmentsDetailReducer,
  websiteMeeting: WebsiteMeetingReducer,

  WebsiteSocketReducerNotification: WebsiteSocketReducer,
  patientProfile: patientProfileReducer,
  doctorDetails: doctorDetailsReducer,
});

export default rootReducer;
