import React from "react";
import { useAttendeeStatus } from "amazon-chime-sdk-component-library-react";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
function MuteContorls({ attendeeId }) {
  const { muted, videoEnabled } = useAttendeeStatus(attendeeId);
  return (
    <div className="mute-controls-div">
      <div>{!muted ? <MicIcon/> : <MicOffIcon  style ={{color:"red"}}/>}</div>
      <div>{!videoEnabled ? <VisibilityOffIcon style ={{color:"red"}}/> : < RemoveRedEyeIcon  />}</div>
    </div>
  );
}

export default MuteContorls;
