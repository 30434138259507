import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./root.reducer";
// import { Env } from "../constants";

// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) =>
//     Env.NODE_ENV === "development"
//       ? getDefaultMiddleware({
//           serializableCheck: {
//             ignoredActions: ["WEBSITE_SOCKET_SAVE_SOCKET_REF"],
//           },
//         }).concat(logger)
//       : getDefaultMiddleware({
//           serializableCheck: {
//             ignoredActions: ["WEBSITE_SOCKET_SAVE_SOCKET_REF"],
//           },
//         }),
//   devTools: Env.NODE_ENV !== "production",
// });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk, logger],
  })
