import React from "react";
import { useSelector } from "react-redux";

function CallEndByPatient(props) {
  const { handleOk, patientName, duration } = props;
  // const { apiData } = useSelector((state) => state?.websiteHome);

  return (
    <div className="popup-cover">
      <div className="calling-popup popup-ui">
        <div
          className="popup-header"
          style={{
            backgroundImage: "linear-gradient(to right, #152567, #53c392)",
          }}
        >
          <h3 className="text-blue bold center">Consultation finished</h3>
          {/* <Icon
            name="close"
            className="close-popup"
            onClick={handleClose}
          ></Icon> */}
        </div>
        <div className="popup-body">
          <p className="center fs22">
            {patientName ? patientName : "User"} has ended the call.
          </p>
          <p className="center fs22">
            Duration of Consultation:&nbsp;
            <span>
              {duration?.hour == 0 ? "" : duration?.hour + " " + "hours" + " "}
            </span>
            <span>
              {duration?.minutes == 0
                ? ""
                : duration?.minutes + " " + "minutes" + " "}
            </span>
            <span>
              {duration?.seconds == 0
                ? ""
                : duration?.seconds + " " + "seconds" + " "}
            </span>
          </p>
        </div>
        <div className="popup-footer">
          <button className="btn btn-primary fs22" onClick={handleOk}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}

export default CallEndByPatient;
