import { videoCallNotifier } from "./actionConstant";

const initialState = {
	doctorClickedStartCall: false,
	patientClickedStartCall: false,
	endCallListener: false,
	patientInitiatedCall: false,
	socketEndCallListener: false,
	patientIsNotOnline: false,
	doctorIsNotOnline: false,
	patientCallEndedSuccess: false,
	patientCallDurationEndedSuccess: false,
	userNotRefreshed: false,
	unavailablePopupIsActiveDr: false,
};

export const videoCallNotifierReducer = (state = initialState, action) => {
	switch (action.type) {
		case videoCallNotifier.VIDEO_CALL_NOTIFIER_DOCTOR:
			return {
				...state,
				doctorClickedStartCall: action.payload,
			};

		case videoCallNotifier.VIDEO_CALL_NOTIFIER_PATIENT:
			return {
				...state,
				patientClickedStartCall: action.payload,
			};
		case videoCallNotifier.VIDEO_CALL_END_CALL_LISTNER:
			return {
				...state,
				endCallListener: action.payload,
			};
		case videoCallNotifier.PATIENT_INITIATED_CALL_LISTNER:
			return {
				...state,
				patientInitiatedCall: action.payload,
			};
		case videoCallNotifier.SOCKET_END_CALL_LISTEN:
			return {
				...state,
				socketEndCallListener: action.payload,
			};
		case videoCallNotifier.PATIENT_IS_NOT_ONLINE:
			return {
				...state,
				patientIsNotOnline: action.payload,
			};
		case videoCallNotifier.DOCTOR_IS_NOT_ONLINE:
			return {
				...state,
				doctorIsNotOnline: action.payload,
			};
		case videoCallNotifier.PATIENT_CALL_ENDED_SUCCESS:
			return { ...state, patientCallEndedSuccess: action.payload };
		case videoCallNotifier.PATIENT_CALL_DURATION_ENDED_SUCCESS:
			return {
				...state,
				patientCallDurationEndedSuccess: action.payload,
			};
		case videoCallNotifier.DOCTOR_NOT_REFRESHED:
			return {
				...state,
				userNotRefreshed: action.payload,
			};
		case videoCallNotifier.UNAVAILABLE_IS_ACTIVE_DR:
			return {
				...state,
				unavailablePopupIsActiveDr: action.payload,
			};
		default:
			return { ...state };
	}
};
