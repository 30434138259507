import { toast } from "react-toastify";
import axiosInstance from "../../api/axios.instance";
import { Alert, getPhoneIdFromLS } from "../../utils";
import { loaderEnd, loaderStart } from "../../features/loader/loader.slice";
import moment from "moment";
// export const getSpecialization = () => {
//   return axiosInstance.get(`${END_POINT.SPECIALIZATION}?parent_id=0&offset=0&limit=20&search=&is_online=1`);
// };
export const getInsuranceCompany = (
  offset: number,
  limit: number,
  search: string,
  doctorId: string
) => {
  return axiosInstance.get(
    `/insurance-company?offset=${offset}&limit=${limit}&search=${search}&doctor_id=${doctorId}&type=doctor_insurance_company_for_pharmacy_consultation`
  );
};

export const getInsuredMember = (
  patientInsuranceId: any,
  setSubmitting: any,
  values: any,
  isFromChemistRegFR: any = false
) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Alert(3, "PLEASE CHECK INTERNET");
      return;
    }
    const { cardType } = getState().patientProfile;
    const { search } = values;
    const FIRST_ASSURANCE = process.env.REACT_APP_FA
    const MAD = process.env.REACT_APP_MAD
    const UAP = process.env.REACT_APP_UAP
    let searchVal = "";
    if (patientInsuranceId == MAD) {
      searchVal = search;
    } else {
      searchVal = search.replace(/\/./g, '-')
      searchVal = searchVal.replace(/\./g, '-')
      if (
        cardType === "1" &&
        !searchVal.includes("-") &&
        !searchVal.includes("USIU") &&
        !(patientInsuranceId == FIRST_ASSURANCE && (searchVal.includes("MEM") || searchVal.includes("KMTC"))) &&
        !(patientInsuranceId == UAP && (searchVal.includes("MEM")))

      ) {
        searchVal = searchVal + "-";
      }
    }

    let data = ``;
    const isFromChemistRegFRKey = isFromChemistRegFR ? `&isFromChemistRegFR=${isFromChemistRegFR}` : '';
    if (cardType !== "1") {
      data = `?insurance_company_id=${patientInsuranceId}${isFromChemistRegFRKey}&is_search_by_smartcard=${cardType === "1" ? 1 : 0
        }&smartcard_provider_name=${""}&from_desktop_portal=${1}${search.length > 0 ? `&search=${searchVal}` : ""
        }&isSearchOnActisure=${true}`;
    } else {
      data = `?insurance_company_id=${patientInsuranceId}&is_search_by_smartcard=${cardType === "1" ? 1 : 0
        }&smartcard_provider_name=${""}&from_desktop_portal=${1}${search.length > 0 ? `&search=${searchVal}${isFromChemistRegFRKey}` : ""
        }`;
    }

    axiosInstance
      .get(`/search-insured-member${data}`)
      .then((respData: any) => {
        let { data } = respData;
        setSubmitting(false);
        dispatch(loaderEnd())
        if (data.code !== 400) {
          if (data.body.length > 0) {
            dispatch({
              type: "SET_PATIENT_PROFILE",
              payload: {
                patientInsuranceMemberDataList: data.body,
                insuranceMemberOptionSelect: false,
              },
            });
          } else {
            Alert(2, "No result found");
          }
        } else {
          dispatch({
            type: "SET_PATIENT_PROFILE",
            payload: {
              patientInsuranceMemberDataList: [],
              insuranceMemberOptionSelect: false,
            },
          });
          Alert(2, data?.messages[0]);
        }
      })
      .catch((error: any) => {
        setSubmitting(false);
        dispatch(loaderEnd())
        let { data } = error;

        dispatch({
          type: "SET_PATIENT_PROFILE",
          payload: {
            patientInsuranceMemberDataList: [],
            insuranceMemberOptionSelect: false,
          },
        });
        Alert(2, data?.messages[0]);
      });
  };
};
export const onSubmitMember = (dataSend: any) => {
  return (dispatch: Function, getState: Function) => {
    const { search } = getState().patientProfile;
    const { patientInsurance } = getState().patientProfile;
    const { insuranceCardType } = getState().patientProfile;
    let data = `?insurance_company_id=${patientInsurance}&is_search_by_smartcard=${insuranceCardType === "1" ? 1 : 0
      }&smartcard_provider_name=${""}&from_desktop_portal=${1}${search.length > 0 ? `&search=${search}` : ""
      }`;
    const dataToSend = dataSend;
    // Utils.constants.getAccessToken();
    axiosInstance
      .post(`/search-insured-member${data}`, dataToSend)
      .then((respData) => {
        let { data } = respData;
        // console.log("linee 135 response", respData);
        dispatch({
          type: "SET_PATIENT_PROFILE",
          payload: {
            patientInsuranceMember: data.body,
            insuranceMemberOptionSelect: true,
          },
        });

        if (
          data.body !== undefined &&
          data.body !== null &&
          data.body.length > 0
        ) {
          let age = moment(data.body[0].dob, "YYYYMMDD")
            .fromNow()
            .match(/\d+/g);
          var phone = data.body[0].phone;
          if (data.body[0].phone !== null && data.body[0].phone !== undefined) {
            phone = data.body[0].phone.slice(3, 12);
          }

          let payload = {
            gender: data.body[0].sex,
            patientName:
              data.body[0].beneficiary_first_name +
              " " +
              data.body[0].beneficiary_last_name,
            patientDoB: data.body[0].dob,
            phoneNumber: phone,
            age: age !== null ? Number(age[0]) : 16,
            isPrincipal: data.body[0].is_principle,
            userId: data.body[0].user_id,
            employeeNumber: data.body[0].employee_number,
            cardType: data.body[0].card_type,
            card1: data.body[0].photo_id_number_frontend,
            card2: data.body[0].photo_id_number_backend,
            finalEntityId: data.body[0].ent_dep_parent_on_policy,
          };

          dispatch({
            type: "SET_PATIENT_PROFILE",
            payload: payload,
          });
          dispatch(
            getMemberFamilyDetails(
              dataSend.entity_id,
              dataSend.employee_number !== null
                ? dataSend.employee_number
                : dataSend.entity_id,
              patientInsurance,
              data.body[0].user_id,
              data.body[0].entity_id
            )
          );
        } else {
          if (data.code === 400) {
            dispatch(loaderEnd())
            dispatch({
              type: "SET_PATIENT_PROFILE",
              payload: {
                patientInsuranceMember: [],
                insuranceMemberOptionSelect: false,
              },
            });
            Alert(2, data?.messages[0]);
          }
        }
      })
      .catch((error) => {
        dispatch(loaderEnd())
        dispatch({
          type: "SET_PATIENT_PROFILE",
          payload: { isLoading: false },
        });
      });
  };
};

export const getMemberFamilyDetails = (
  entityId: any,
  search: any,
  patientInsuranceId: any,
  principleUserId: any,
  finalEntity: any
) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      //check if user is online or not
      Alert(3, "PLEASE CHECK INTERNET");
      return;
    }
    const { cardType } = getState().patientProfile;
    dispatch(loaderStart());
    const benefittype = principleUserId
      ? "patient_insurance_company_md"
      : "get_benefits_with_entity";
    // const { search } = getState().InsurancePatientProfileReducer;
    let data = `?limit=1000&offset=0&insurance_company_id=${patientInsuranceId}&entity_id=${entityId}&is_search_by_smartcard=${cardType === "1" ? 1 : 0
      }&smartcard_provider_name=${""}&from_desktop_portal=${1}${search.length > 0
        ? `&search=${search}&user_id=${principleUserId !== null ? principleUserId : ""
        }&type=${benefittype}&from_md=${"md_portal"}&list_for_md_claims=${true}`
        : ""
      }`;

    axiosInstance
      .get(`/insurance-company${data}`)
      .then((respData: any) => {
        let { data } = respData;
        dispatch(loaderEnd());
        if (
          data.body !== undefined &&
          data.body !== null &&
          data.body.length > 0
        ) {
          dispatch({
            type: "SET_PATIENT_PROFILE",
            payload: {
              benefits: data.body[0].benefits,
              policyStatus: data.body[0].policy_status,
              insuranceMemberDependents:
                data.body[0].insurance_member_dependents,
              patientFamilyMemberData: data.body,
              finalEntityId: finalEntity,
            },
          });
        }
      })
      .catch((error: any) => {
        dispatch(loaderEnd());
        let { data } = error;
        Alert(2, data?.messages[0]);
      });
  };
};

export const getAccessToken = (id) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.patch(`guest-user/${id}`, {
        os_type: 3,
        phone_id: getPhoneIdFromLS(),
      });
      resolve(response);
    } catch (error) {
      if (!toast.isActive("apiFailure")) {
        toast.error(error?.response?.data?.messages[0], {
          toastId: "apiFailure",
          className: "toast-error",
        });
      }
      reject(error);
    }
  });
};

export const checkAvailablity = (doctorId: string) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.get(`/event?doctor_id=${doctorId}`);
      resolve(response);
    } catch (error) {
      if (!toast.isActive("apiFailure")) {
        toast.error(error?.response?.data?.messages[0], {
          toastId: "apiFailure",
          className: "toast-error",
        });
      }
      reject(error);
    }
  });
};

export const bookAppointment = async (payload: any) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.put(`/event`, payload);
      resolve(response);
    } catch (error) {
      if (!toast.isActive("apiFailure")) {
        toast.error(error?.response?.data?.messages[0], {
          toastId: "apiFailure",
          className: "toast-error",
        });
      }
      reject(error);
    }
  });
};
