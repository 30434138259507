import React from 'react';
import clsx from "clsx";
import { Icon } from "semantic-ui-react";
import { 
    Dialog, 
    DialogActions, 
    DialogContent,  
    DialogTitle, 
    Button,
    useTheme,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({    
    dialogActions:{
      "&.MuiDialogActions-root":{
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }
    },
    dialogTitle:{
      fontSize: 24,
      fontWeight: 500,
      color: "primary",
        "&.MuiDialogTitle-root":{
          display: "flex",
          justifyContent: "space-between",
        }
    },
    dialogContent:{
        "&.MuiDialogContent-root":{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }
    }, 
    buttonPrimary:{
        color: "white !important",
        textTransform: "capitalize",
        fontSize: 22,
        fontWeight: 600,
      },       
  })
);

function CustomModal(props: any) {
    const { 
        // title,
        // content,
        // open,
        // handleClose,
        // handleButtonClick,
        // // isApiLoading,
        // addCustomActions,
        // actionItems,
        header,
        open,
        handleClose,
        content,
        handleConfirm,
        loading,
        hideConfirmButton,
        cancelButtonText,
        confirmButtonText,
        fr,
        timeLeft,
    } = props;
    const theme = useTheme();
    const classes = useStyles({ theme });

  return (
    // <div>
    //     <Dialog
    //         open={open} 
    //         onClose={handleClose}
    //     >
    //     {title && 
    //       <DialogTitle className={classes.dialogTitle}>
    //         {title}
    //         <CloseIcon
    //             sx={{
    //                 position: 'relative',
    //                 top: 5,
    //                 ml: 2,
    //                 color: (theme) => theme.palette.grey[500],
    //                 cursor: "pointer",
    //               }}
    //             onClick={handleClose}
    //         />
    //       </DialogTitle>
    //     }        
    //     <DialogContent className={classes.dialogContent} >
    //         {content}
    //     </DialogContent>
    //     <DialogActions 
    //         className={classes.dialogActions}
    //     >
    //         {!addCustomActions &&
    //             <Button 
    //                 variant="contained"
    //                 className={classes.buttonPrimary}
    //                 onClick={handleButtonClick}
    //             >
    //                 OK
    //             </Button>
    //         }
    //         {addCustomActions && 
    //             actionItems
    //         }
    //     </DialogActions>
    //   </Dialog>
    // </div>
    <>
    {open && 
      <div className="popup-cover">
        <div className="confirm-popup popup-ui">
          <div className="popup-header">
            <Typography variant='h5'>
              {header ? header : ""}
            </Typography>
            {handleClose && <Icon
              name="close"
              className="close-popup"
              onClick={handleClose}
            ></Icon>
            }
          </div>
          <div className="popup-body">
              <>{content}</>
          </div>
          <div className="popup-footer">
            {handleClose && (
              <button
                className="btn btn-simple"
                disabled={loading}
                onClick={handleClose}
              >
                {cancelButtonText ? cancelButtonText : "Cancel"}
              </button>
            )}
            {!hideConfirmButton && (
              <button
                className={clsx({
                  btn: true,
                  "btn-primary": true,
                  "on-load": loading,
                })}
                style={timeLeft ? { padding: "5px 20px" } : {}}
                onClick={handleConfirm}
                disabled={timeLeft}
              >
                <span style={{ paddingRight: 5 }}>
                  {confirmButtonText ? confirmButtonText : "Confirm"}
                </span>
                <div className="ui active dimmer">
										<div className="ui loader"></div>
									</div>
								</button>
							)}
          </div>
        </div>
      </div>
    }
    </>
  );
}

export default CustomModal;
