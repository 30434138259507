import {
  commonAction,
  MEETINGREDUCERNAME,
  relatedAction,
} from '../MeetingChime/actionConstant'

const initialState = {
  meetingApiData: null,
  isApiLoading: false,
  apiError: null,
  isDoctorNoteSaveApiLoading: false,
}

export const MeetingReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${MEETINGREDUCERNAME}_${commonAction.IS_API_LOADING}`:
      return {
        ...state,
        isApiLoading: action.payload,
      }

    case `${MEETINGREDUCERNAME}_${commonAction.SET_API_DATA}`:
      return {
        ...state,
        meetingApiData: action.payload,
        isApiLoading: false,
      }

    case `${MEETINGREDUCERNAME}_${commonAction.SET_API_ERROR}`:
      return {
        ...state,
        apiError: action.payload,
        isApiLoading: false,
      }
    case `${MEETINGREDUCERNAME}_${relatedAction.IS_SAVE_DOCTOR_NOTES_API_LOADING}`:
      return {
        ...state,
        isDoctorNoteSaveApiLoading: action.payload,
        isApiLoading: false,
      }

    default:
      return { ...state }
  }
}
