import React from "react";
import arrowTopImg from "../../../assets/calling-icons/top-arrow.png";
import videoErrorImg from "../../../assets/calling-icons/video-error.png";
import googleBlockedIcon from "../../../assets/calling-icons/Blocked.svg";

import "./GoogleBlockedUI.css";
import { Icon } from "semantic-ui-react";
// import { withRouter } from "react-router";

function GoogleBlockeUI({ videoBlock, audioBlock, handleClosePopup }) {
	console.log("props", audioBlock, videoBlock, window.location.host);
	const checkBlockers = () => {
		if (videoBlock && !audioBlock) {
			return <p className="text-cam-off">Your microphone is blocked</p>;
		}
		if (!videoBlock && audioBlock) {
			return <p className="text-cam-off">Your camera is blocked</p>;
		}
		if (!videoBlock && !audioBlock) {
			return (
				<p className="text-cam-off">Your camera and microphone are blocked</p>
			);
		}
	};

	const onlyCameraString = () => {
		if (videoBlock && !audioBlock) {
			return "microphone";
		}
		if (!videoBlock && audioBlock) {
			return "camera";
		}
		if (!videoBlock && !audioBlock) {
			return "camera and microphone";
		}
	};

	return (
		<div className="popup-cover for-blocked-ui element">
			<div className="blocked-ui-content">
				<div className="arrow-cover center mb-20">
					<img src={arrowTopImg} style={{ width: "80px" }} />
				</div>

				<Icon
					name="close"
					className="icon-close"
					size="large"
					onClick={handleClosePopup}
				></Icon>

				<div className="url-cover">
					{window.location.host}
					<img src={videoErrorImg} />
				</div>

				<div style={{ padding: "0px 25px", fontSize: "15px" }}>
					<h2>{checkBlockers()}</h2>
					<p>Livia needs access to your {onlyCameraString()}. To use Livia,</p>
					<ol>
						<li>
							Click the camera blocked icon{" "}
							<img
								src={googleBlockedIcon}
								style={{ verticalAlign: "middle" }}
							/>{" "}
							in your browser's address bar
						</li>
						<li>Allow access and then refresh the page</li>
					</ol>
				</div>
			</div>
		</div>
	);
}

export default GoogleBlockeUI;
