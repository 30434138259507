import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Providers from './components/providers/provider';
import Toaster from './components/toaster/toaster';
//@ts-ignore
import { ThemeProvider } from "styled-components";
//@ts-ignore
import {
  MeetingProvider,
  lightTheme,
  MeetingManager,
  UserActivityProvider,
} from "amazon-chime-sdk-component-library-react";
// import { ThemeProvider } from "styled-components";
import { LogLevel } from "amazon-chime-sdk-js";

const meetingManager = new MeetingManager({
  logLevel: LogLevel.INFO,
  //@ts-ignore
  configuration: {
    connectionHealthPolicyConfiguration: {
      connectionWaitTimeMs: 20000,
      connectionUnhealthyThreshold: 20,
      missedPongsUpperThreshold: 3,
    },
  },
});

const meetingConfig = {
  simulcastEnabled: true,
  meetingManager,
};
//@ts-ignore
const root: any = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
//@ts-ignore
root.render(
  <ThemeProvider theme={lightTheme}>
    <MeetingProvider {...meetingConfig}>
      <UserActivityProvider>
        <Providers>
          <Toaster />
          <App />
        </Providers>
      </UserActivityProvider>
    </MeetingProvider>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
