import { commonAction, WEBSITEMEETINGREDUCERNAME } from "../MeetingChime/actionConstant"

const initialState = {
    meetingApiData: null,
    isApiLoading: false,
    apiError: null
}

export const WebsiteMeetingReducer = (state = initialState, action) => {
    switch(action.type){
        case `${WEBSITEMEETINGREDUCERNAME}_${commonAction.IS_API_LOADING}`:
            return{
                ...state,
                isApiLoading:action.payload
            }
        
        case `${WEBSITEMEETINGREDUCERNAME}_${commonAction.SET_API_DATA}`:
            return{
                ...state,
                meetingApiData:action.payload,
                isApiLoading:false
            }
        
        case `${WEBSITEMEETINGREDUCERNAME}_${commonAction.SET_API_ERROR}`:
            return{
                ...state,
                apiError:action.payload,
                isApiLoading:false
            }
        
        default:
            return{...state}
        
    }
}