import moment from "moment";

export const currentYear = () => {
  return moment().format("YYYY");
};

export function getPhoneIdFromLS() {
  return localStorage.getItem("phone_id");
}

export function getPharmacyNameFromLS() {
  return localStorage.getItem("pharmacy_name");
}

export function getPharmacyImageFromLS() {
  return localStorage.getItem("pharmacy_image");
}

export function getPharmacyIdFromLS() {
  return localStorage.getItem("pharmacy_id");
}

export function setPharmacyDetailsInLS(
  pharmacyId: string,
  pharmacyName: string,
  pharmacyImage: string
) {
  localStorage.setItem("pharmacy_id", pharmacyId);
  localStorage.setItem("pharmacy_name", pharmacyName);
  localStorage.setItem("pharmacy_image", pharmacyImage);
}

export function getDeviceIdFromLS() {
  return localStorage.getItem("device_id");
}

export const setDeviceIdInLS = () => {
  if (getDeviceIdFromLS()) {
    return;
  } else {
    // let deviceId = "xxxxxxxxxxxx-0xxxxxxxx-yxx".replace(
    // 	/[xy]/g,
    // 	(c) => {
    // 		const r = (Math.random() * 16) | 0;
    // 		const v = c === "x" ? r : (r & 0x3) | 0x8;
    // 		return v.toString(16);
    // 	}
    // )
    var navigator_info: any = window.navigator;
    var screen_info: any = window.screen;
    var uid: any = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, "");
    uid += navigator_info.plugins.length;
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    uid += screen_info.pixelDepth || "";
    console.log(uid);
    localStorage.setItem("device_id", uid);
  }
};

export const getPhoneId = () => {
  if (getPhoneIdFromLS()) {
    return getPhoneIdFromLS();
  } else {
    let phoneId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    localStorage.setItem("phone_id", phoneId);
    return phoneId;
  }
};

export function getTabIdBySesionStorage() {
  return sessionStorage.getItem("tabId");
}

export const getTabId = () => {
  if (getTabIdBySesionStorage()) {
    return getTabIdBySesionStorage();
  } else {
    let tabId = "xxxxxxxx-4xxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    sessionStorage.setItem("tabId", tabId);

    return tabId;
  }
};
