import React from "react";
// import { roles } from "../../../utility/roleConstant";
// import { getRoleIdInLS } from "../../../utility/util";
import { useSelector } from "react-redux";

function UserNotAvailableForCall(props) {
  const { name, onHide } = props;
  // const { apiData } = useSelector((state) => state?.websiteHome);
  return (
    <div className="popup-cover">
      <div className="calling-popup popup-ui">
        <div
          className="popup-header"
          style={
            {
              backgroundImage: "linear-gradient(to right, #152567, #53c392)",
            }

            // : { backgroundColor: apiData?.body?.body_data?.header_bg_color }
          }
        >
          <h3 className="text-blue bold center fs22">Alert</h3>
        </div>
        <div className="popup-body">
          <p className="center fs22">
            {name ? name : "User"} is unavailable. Please try again later.
          </p>
        </div>
        <div className="popup-footer">
          <button className="btn btn-primary fs22" onClick={onHide}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserNotAvailableForCall;
