import { useEffect, useState } from 'react'
import { makeStyles, createStyles } from "@mui/styles";
//@ts-ignore
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Avatar,
  Grid,
  Theme,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
  Card,
  CardContent,
  ThemeProvider,
  Divider,
  InputAdornment,
  Button,
  CardMedia,
  TextField,
  Checkbox,
  Box,
} from "@mui/material";
import { Check, Close, Phone } from '@mui/icons-material';
import { Formik, Form } from "formik";
import theme from '../../theme/theme'
import CustomModal from "../../components/modal/customModal";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { patientProfileInitialState } from './patientProfile.slice';
import { getInsuranceCompany, getInsuredMember, bookAppointment, checkAvailablity, getMemberFamilyDetails, onSubmitMember, getAccessToken } from './patientProfile.api';
import FR from '../../components/facial-recognition/facial-recognition';
import { Env, ROUTE_NAME } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { Alert, getPharmacyIdFromLS } from '../../utils';
import moment from 'moment';
import { loaderEnd, loaderStart } from '../../features/loader/loader.slice';
import { doctorDetailsInitialState } from '../../redux-initial-state';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    flexBox: {
      display: "flex",
      alignItems: "center",
      "& .MuiAvatar-root": {
        height: "25px",
        width: "20px",
      },
    },
    spaceBetween: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    w100: {
      width: "100%",
    },
    h100: {
      height: "100%",
    },
    marginFlag: {
      marginLeft: "10px",
      textTransform: "uppercase",
      fontSize: "20px",
    },
    marginPhoneDropdown: {
      marginLeft: "10px",
      textTransform: "uppercase",
      fontSize: "20px",
    },
    formItem: {
      backgroundColor: "#fff",
      // border: "none",
      ".MuiInputBase-root-MuiFilledInput-root ": {
        backgroundColor: "#fff !important",
        border: "none",
      }
    },
    label: {
      fontWeight: 600,
      fontSize: 20,
      marginBottom: '5px'
    },
    radioContainer: {
      margin: '10px 20px',
      "& .MuiFormControlLabel-root": {
        alignItems: "flex-start",
      },
    },
    test: {
      borderRadius: "12px",
      alignItems: "center !important",
      "& .MuiTypography-root": {
        width: "100%",
      },
    },
    radioColor: {
      color: "primary",
      display: "none",
    },
    closeIcon: {
      position: "absolute",
      top: -10,
      right: -10,
      cursor: "pointer",
      width: "20px",
      height: "20px",
    },
    checkboxPhone: {
      color: "#222 !important",
      "& .PrivateSwitchBase-input": {
        opacity: "0 !important",
      }
    },
    // cardContainer: {
    //   maxWidth: "50%"
    // },
    // cardNumberField: {
    //   minWidth: "70%"
    // },
    // cardSubmitButton: {
    //   minWidth: "30%",
    //   maxWidth: "30%"
    // },
    // cardContainerForRowAlignment: {
    //   display: "flex", flexDirection: "row",
    //   flexWrap: "nowrap",
    //   // [theme.breakpoints.up('sm')]: {
    //   //   width: "100%"
    //   // },
    // }
  })
);

function VerifyPatient() {
  const type = "Outpatient"
  const classes = useStyles({ theme });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);
  const {
    patientInsurance,
    cardList,
    cardType,
    showSearchMemberNo,
    patientInsuranceMember,
    patientName,
    patientDoB,
    phoneNumber,
    patientFamilyMemberData,
    search,
    familyMemberPhoneNumber,
    principalPhoneNumber,
    familyMemberPhoneChecked,
    insuranceMemberOptionSelect,
    userId,
    entityId,
    // employeeNumber,
    age,
    gender,
    checked,
    isPrincipal,
    // beneficiaryId,
    patientCountryCode,
    policyStatus,
    patientInsuranceMemberDataList,
    insuranceMemberDependents,
    consultationFees,
    benefits,
    fundedBy
  } = useAppSelector(state => state.patientProfile)
  const [memberData, setMemberData] = useState(null)
  const [selectCardType, setSelectCardType] = useState(null)
  const {
    id,
    hospital_id,
  } = useAppSelector(state => state.doctorDetails)

  /**funded by  */
  function getBenefitLiaisonBalance(type, benefit) {
    if (type == "Outpatient" && benefit["serviceId"] == 96) {
      return benefit;
    } else if (type == "Inpatient" && benefit["serviceId"] == 98) {
      return benefit;
    } else if (type == "Dental" && benefit["serviceId"] == 99) {
      return benefit;
    }
    return false;
  }
  function searchBenefitWithType(type, benefit) {
    if (
      type == "Outpatient" ||
      type == "Counselling" ||
      type == "Physiotherapy"
    ) {
      if (
        benefit.name.toLowerCase().includes(" out patient ") ||
        benefit.name.toLowerCase().includes("out patient ") ||
        benefit.name.toLowerCase().includes(" out patient") ||
        benefit.name.toLowerCase().includes("out patient") ||
        benefit.name.toLowerCase().includes("out patient/") ||
        benefit.name.toLowerCase().includes(" out patient/") ||
        benefit.name.toLowerCase().includes(" outpatient") ||
        benefit.name.toLowerCase().includes("outpatient ") ||
        benefit.name.toLowerCase().includes(" outpatient ") ||
        benefit.name.toLowerCase().includes("outpatient") ||
        benefit.name.toLowerCase().includes("outpatient/") ||
        benefit.name.toLowerCase().includes(" outpatient/") ||
        benefit.name.toLowerCase().includes(" out-patient ") ||
        benefit.name.toLowerCase().includes("out-patient ") ||
        benefit.name.toLowerCase().includes(" out-patient") ||
        benefit.name.toLowerCase().includes("out-patient") ||
        benefit.name.toLowerCase().includes("out-patient/") ||
        benefit.name.toLowerCase().includes(" out-patient/")
      ) {
        return benefit;
      }
    } else if (type == "Inpatient") {
      if (
        benefit.name.toLowerCase().includes(" in patient ") ||
        benefit.name.toLowerCase().includes("in patient ") ||
        benefit.name.toLowerCase().includes(" in patient") ||
        benefit.name.toLowerCase().includes("in patient") ||
        benefit.name.toLowerCase().includes("in patient/") ||
        benefit.name.toLowerCase().includes(" in patient/") ||
        benefit.name.toLowerCase().includes(" inpatient") ||
        benefit.name.toLowerCase().includes("inpatient ") ||
        benefit.name.toLowerCase().includes(" inpatient ") ||
        benefit.name.toLowerCase().includes("inpatient") ||
        benefit.name.toLowerCase().includes("inpatient/") ||
        benefit.name.toLowerCase().includes(" inpatient/") ||
        benefit.name.toLowerCase().includes(" in-patient ") ||
        benefit.name.toLowerCase().includes("in-patient ") ||
        benefit.name.toLowerCase().includes(" in-patient") ||
        benefit.name.toLowerCase().includes("in-patient") ||
        benefit.name.toLowerCase().includes("in-patient/") ||
        benefit.name.toLowerCase().includes(" in-patient/")
      ) {
        return benefit;
      }
    } else if (type == "Dental")
      if (
        benefit.name.toLowerCase().includes(" dental") ||
        benefit.name.toLowerCase().includes("dental ") ||
        benefit.name.toLowerCase().includes(" dental ") ||
        benefit.name.toLowerCase().includes("dental/") ||
        benefit.name.toLowerCase().includes(" dental/") ||
        benefit.name.toLowerCase().includes("dental")
      ) {
        return benefit;
      }
  }

  const extractFundedByTxt = (subbenefit: any) => {

    let benefitStr = subbenefit?.toLowerCase()
    if (benefitStr.includes('(') && benefitStr.includes(')')) {
      let fundedbyStr = benefitStr.substring(
        benefitStr.indexOf('(') + 1,
        benefitStr.indexOf(')')
      );
      return fundedbyStr
    }
    return benefitStr

  }
  const fundedBySomeone = (subbenefit: any, patientInsurance: any) => {
    const UAP = process.env.REACT_APP_UAP
    const EABL = process.env.REACT_APP_EABL
    const KQ = process.env.REACT_APP_KQ
    const JUBILEE = process.env.REACT_APP_JUBILEE
    let fundedTxt = ''
    if (subbenefit?.name?.toLowerCase()?.includes('funded')) {
      fundedTxt = extractFundedByTxt(subbenefit);
    }
    if (patientInsurance == UAP || patientInsurance == EABL) {
      if (subbenefit?.name?.toLowerCase()?.includes('funded') && !subbenefit?.name?.toLowerCase()?.includes('funded by old mutual')) {
        return extractFundedByTxt(subbenefit);
      }
    } else if (patientInsurance == KQ || patientInsurance == JUBILEE) {
      if (subbenefit?.name?.toLowerCase()?.includes('funded') && fundedTxt?.toLowerCase() != 'funded by jubilee' && fundedTxt?.toLowerCase() != 'funded') {
        return extractFundedByTxt(subbenefit);
      }
    } else {
      if (subbenefit?.name?.toLowerCase()?.includes('funded')) {
        return extractFundedByTxt(subbenefit);
      }
    }

    return ''
  }

  useEffect(() => {
    if (policyStatus === 1) {
      const benefitRes =
        benefits?.length > 0 &&
        benefits?.filter((benefit) => {
          if (benefit) {
            return cardType == 3
              ? getBenefitLiaisonBalance(type, benefit)
              : searchBenefitWithType(type, benefit);
          }
        });

      if (cardType == 2) {

        const benefitFundedBy = fundedBySomeone(benefitRes[0], patientInsurance)
        if (benefitFundedBy) {
          dispatch({
            type: 'SET_PATIENT_PROFILE',
            payload: {
              fundedBy: "E-Claim not available. Seek email preauthorization on preauth@liviaapp.com. The scheme is " + benefitFundedBy + '.',
            },
          })
        }
        // dispatchFormData('fundedBy',benefitFundedBy)
      }
    }
  }, [policyStatus])

  const cardListArray = [];
  //   const { isLoading } = useSelector((state: ReducersModal) => state.globalLoaderReducer);
  const [initialValue, setInitialValue] = useState({
    patientDob: patientDoB,
    gender: gender,
    patientName: patientName,
    phoneNumber: phoneNumber,
  });
  const insuranceData: any = insuranceCompanies.find((a: any) => a.id === patientInsurance);
  const getMale = (userName: any) => {
    if (
      userName.includes("Miss") ||
      userName.includes("Ms") ||
      userName.includes("Mrs")
    ) {
      return "Female";
    } else {
      return "Male";
    }
  };
  useEffect(() => {
    if (id) {
      getInsuranceCompany(0, 100, "", id)
        .then(res => {
          setInsuranceCompanies(res.data.body);
        })
        .catch(err => {
          let { data } = err;
          Alert(2, data?.messages[0]);
        });
    } else {
      navigate("/");
    }

    return () => {
      dispatch({
        type: 'SET_PATIENT_PROFILE',
        payload: patientProfileInitialState
      })
      dispatch({
        type: 'SET_DOCTOR_DETAILS',
        payload: doctorDetailsInitialState
      });
    }
  }, []);

  useEffect(() => {
    if (patientInsurance) {
      if (insuranceData.is_insurance_card === "1") {
        cardListArray.push({
          id: 2,
          name: insuranceData.name === "Liaison Insurance" ? "Search with LCT Member No." : "Search with Member No.",
          isInsuranceCard: insuranceData.is_insurance_card,
          image: insuranceData.insurance_card_image,
        });
      }
      if (insuranceData.is_smart_card === "1") {
        cardListArray.push({
          id: 1,
          name: "Search with Smart Card",
          isSmartCard: insuranceData.is_smart_card,
          image: insuranceData.smart_card_image,
        });
      }

      dispatch({
        type: 'SET_CARD_LIST',
        payload: cardListArray
      })
    }
  }, [patientInsurance, insuranceData])

  useEffect(() => {
    let showPopup;
    if (policyStatus == 1 &&
      entityId &&
      !patientInsuranceMember[0]?.face_id) {
      setShowInfoPopup(true);
      showPopup = setTimeout(() => {
        navigate('/');
      }, 30000)
    }
    return () => {

      clearTimeout(showPopup)
    }
  }, [policyStatus, entityId, patientInsuranceMember[0]?.face_id])

  const chooseCardType = (event: any) => {
    dispatch({
      type: 'SET_PATIENT_PROFILE',
      payload: {
        cardType: event.target.value,
        showSearchMemberNo: true,
      },
    });
    setSelectCardType(event.target.value)
  };

  const policyCurrentStatus = (policyStatus: number) => {
    let SUPPORT_NUMBER = "0114081492";
    switch (policyStatus) {
      case 1:
        return "Policy active";
      case 2:
        return "Policy expired. Please call " + SUPPORT_NUMBER;
      case 3:
        return "Insufficient balance. Please call " + SUPPORT_NUMBER;
      case 4:
        return "Balance not retrieved. Please call " + SUPPORT_NUMBER;
      case 5:
        return "Sorry, the patient's scheme can not be used to create a Doctor claim.";
      case 8:
        return "Time out error. Unable to retrieve benefit details. Try again later.";
      default:
        return "";
    }
  };

  const searchMember = (beneficiary: any) => {
    let beneficiaryName = ''
    if (beneficiary?.card_type === "1") {
      beneficiaryName = beneficiary?.entity_id;
    } else if (beneficiary?.card_type === "2") {
      beneficiaryName = beneficiary?.employee_number;
    } else if (beneficiary?.card_type === "3") {
      beneficiaryName = beneficiary?.employee_number;
    } else {
      beneficiaryName = beneficiary?.entity_id;
    }
    return beneficiaryName;
  };
  useEffect(() => {
    if (insuranceMemberDependents?.length)
      dispatch({
        type: 'SET_PATIENT_PROFILE',
        payload: {
          phoneNumber: phoneNumber ? phoneNumber : insuranceMemberDependents[0]?.login_phone,
          familyMemberPhoneNumber: insuranceMemberDependents[0]?.login_phone,
        },
      });
  }, [insuranceMemberDependents])
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    // setFieldValue: any
  ) => {
    if (familyMemberPhoneNumber !== "" && familyMemberPhoneNumber !== null) {
      if (event.target.checked) {
        dispatch({
          type: 'SET_PATIENT_PROFILE',
          payload: {
            phoneNumber: familyMemberPhoneNumber,
          },
        });
        setInitialValue({
          ...initialValue,
          phoneNumber: familyMemberPhoneNumber,
        });
      } else {
        dispatch({
          type: 'SET_PATIENT_PROFILE',
          payload: {
            phoneNumber: "",
          },
        });
        setInitialValue({
          ...initialValue,
          phoneNumber: "",
        });
      }
    } else if (principalPhoneNumber !== null && principalPhoneNumber !== undefined && principalPhoneNumber !== "") {
      if (event.target.checked) {
        dispatch({
          type: 'SET_PATIENT_PROFILE',
          payload: {
            phoneNumber: principalPhoneNumber,
          },
        });
        setInitialValue({
          ...initialValue,
          phoneNumber: principalPhoneNumber,
        });
      } else {
        dispatch({
          type: 'SET_PATIENT_PROFILE',
          payload: {
            phoneNumber: "",
          },
        });
        setInitialValue({
          ...initialValue,
          phoneNumber: "",
        });
      }
    }
    dispatch({
      type: 'SET_PATIENT_PROFILE',
      payload: {
        checked: event.target.checked,
      },
    });
  };

  const chooseInsuranceMemberOptions = (event: any) => {
    const memberData: any = patientInsuranceMemberDataList.find((a: any) => a.entity_id === event.target.value);
    setMemberData(memberData)
    dispatch({
      type: 'SET_PATIENT_PROFILE',
      payload: {
        entityId: event.target.value,
        finalEntityId:
          insuranceMemberDependents === null || insuranceMemberDependents === undefined
            ? memberData.ent_dep_parent_on_policy
            : event.target.value,
        search:
          memberData.card_type === 1 || memberData.card_type === "1"
            ? memberData.entity_id
            : memberData.employee_number,
        insuranceMemberOptionSelect: false,
      },
    });

    dispatch({
      type: 'SET_PATIENT_PROFILE',
      payload: {
        patientInsuranceMember: [memberData],
        insuranceMemberOptionSelect: true,
      },
    });

    let age = moment(memberData.dob, "YYYYMMDD")
      .fromNow()
      .match(/\d+/g);
    var phone = memberData.phone;
    if (memberData.phone !== null && memberData.phone !== undefined) {
      phone = memberData.phone.slice(3, 12);
    }
    dispatch({
      type: 'SET_PATIENT_PROFILE',
      payload: {
        gender: memberData.sex,
        patientName:
          memberData.beneficiary_first_name +
          " " +
          memberData.beneficiary_last_name,
        patientDoB: memberData.dob,
        phoneNumber: phone,
        age: age !== null ? Number(age[0]) : 16,
        isPrincipal: memberData.is_principle,
        userId: memberData.user_id,
        employeeNumber: memberData.employee_number,
        // cardType: memberData.card_type,
        finalEntityId: memberData.ent_dep_parent_on_policy,
      },
    });
    //@ts-ignore
    dispatch(loaderStart());
    //@ts-ignore
    dispatch(onSubmitMember(memberData))
    //@ts-ignore
    // dispatch(getMemberFamilyDetails(memberData.entity_id, search, patientInsurance, memberData.principal_user_id, memberData.user_id))
  };

  const insuranceMemberOptions = () => {
    return (
      <Card sx={{ width: '60%' }}>
        <CardContent>
          <Typography variant='body2'>Insurance Member Options</Typography>
          <RadioGroup
            value={entityId}
            onChange={(event: any) => chooseInsuranceMemberOptions(event)}

          >
            {
              patientInsuranceMemberDataList.length > 0 &&
              patientInsuranceMemberDataList.map((item: any, index: any) => {
                return (
                  <div key={index} className={classes.radioContainer}>
                    <FormControlLabel
                      value={item.entity_id}
                      className={classes.test}
                      control={<Radio className={`${classes.radioColor}`} />}
                      label={
                        <div style={{ display: "flex" }}>
                          <Typography>
                            {/* {`${item.family_code} - ${item.employee_number}`} */}
                            {item.card_type === 1 || item.card_type === "1"
                              ? `${item.beneficiary_name}${" | "}${item.entity_id}`
                              : `${item.beneficiary_name}${" | "}${item.employee_number}`}
                          </Typography>
                        </div>
                      }
                    />
                  </div>
                );
              })
            }
          </RadioGroup>
        </CardContent>
      </Card>
    );
  };


  const PatientInfo = () => {
    return (
      <Grid container spacing={2} md={12} >
        <Grid item sm={12} md={4} className="cardContainer" >
          <Typography className={classes.label}>Select Your Card Type</Typography>
          <FormControl className={`${classes.w100} ${classes.h100}`}>
            <RadioGroup
              name="insurance-card-type"
              value={cardType}
              onChange={(event: any) => chooseCardType(event)}
              className="cardContainerForRowAlignment"
            >
              {cardList.map((item: any) => {
                return (
                  <div key={item.id} className={classes.radioContainer}>
                    <FormControlLabel
                      value={item.id}
                      control={<Radio className={`${classes.radioColor}`} />}
                      label={
                        <img
                          style={{
                            width: 200,
                            padding: "8px",
                            border: `${selectCardType == item.id ? "2px solid #000" : "none"}`,
                          }}
                          src={`${Env.BASE_URL}${item.image}`}
                          alt={item.name}
                        />
                      }
                    />
                  </div>
                );
              })}
            </RadioGroup>
          </FormControl>
        </Grid>
        {showSearchMemberNo ? (
          <Grid item sm={12} md={8} className="cardContainer" >
            <Formik
              enableReinitialize
              initialValues={{
                search: search,
              }}
              // validationSchema={Schema.memberSearchSchema(cardType, insuranceData)}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values, setSubmitting)
                //@ts-ignore
                // dispatch(loaderStart())
                //@ts-ignore
                dispatch(getInsuredMember(patientInsurance, setSubmitting, values, 1));
              }}
            >
              {({ isSubmitting, isValid }) => (
                <Form>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item sm={9} md={6} className="cardNumberField">
                        <Typography className={classes.label}>
                          {
                            cardType === "1"
                              ? "Smart Card"
                              : insuranceData !== undefined && insuranceData.id === "13"
                                ? "LCT Member No."
                                : "Member No."
                          }
                        </Typography>
                        <TextField
                          sx={{ width: "100%" }}
                          className={classes.formItem}
                          name="search"
                          placeholder={
                            cardType === "1"
                              ? "Search with Smart Card"
                              : insuranceData !== undefined && insuranceData.id === "13"
                                ? "Search with LCT Member No."
                                : "Search with Member No."
                          }
                          size="small"
                          value={search}
                          onChange={(event: any) => {
                            dispatch({
                              type: 'SET_PATIENT_PROFILE',
                              payload: {
                                search: event.target.value,
                                patientInsuranceMemberDataList: [],
                                patientFamilyMemberData: [],
                                patientInsuranceMember: [],
                                policyStatus: "",
                                employeeNumber: "",
                                entityId: "",
                                insuranceMemberOptionSelect: false,
                                phoneNumber: "",
                                checked: false,
                                insuranceMemberDependents: [],
                                familyMemberPhoneNumber: "",
                                principalPhoneNumber: "",
                                principalUserId: "",
                              },
                            });
                          }}
                        />
                        {/* {patientFamilyMemberData !== undefined &&
                          patientFamilyMemberData !== null &&
                          patientFamilyMemberData?.length > 0 ? ( */}
                        <Typography
                          variant='body2'
                          sx={{
                            color: (policyStatus === 1 && !fundedBy) ? "green" : "red",
                            marginTop: "5px",
                            fontWeight: 600,
                            display: 'flex'
                          }}
                        >
                          {policyStatus && ((policyStatus === 1 && !fundedBy) ? <Check /> : <Close />)}
                          {fundedBy ? fundedBy : policyCurrentStatus(policyStatus)}
                        </Typography>
                      </Grid>
                      <Grid item sm={3} md={6} className="cardSubmitButton">
                        <LoadingButton
                          loadingPosition={isSubmitting ? "start" : "end"}
                          className='btn btn-primary '
                          size="small"
                          style={{ marginTop: '33px', minWidth: 150, fontSize: "inherit" }}
                          type="submit"
                          loading={isSubmitting}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Loading..." : "Proceed"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </div>
                  {patientInsurance &&
                    patientInsuranceMemberDataList &&
                    patientInsuranceMemberDataList.length > 0 &&
                    !insuranceMemberOptionSelect
                    ? insuranceMemberOptions()
                    : ""}
                </Form>
              )}
            </Formik>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    );
  };

  const handleSelectChange = (event: any) => {
    const insuranceSelected = insuranceCompanies.filter(item => item.id === event.target.value)

    dispatch({
      type: 'SET_PATIENT_PROFILE',
      payload: {
        ...patientProfileInitialState,
        patientInsurance: insuranceSelected[0].id,
        consultationFees: insuranceSelected[0].consultation_fees,
      }
    })
    setSelectCardType(null)
  };

  const proceedToCall = async () => {
    dispatch(loaderStart());
    const availableResponse: any = await checkAvailablity(id) //doctorId, hospitalId

    if (availableResponse?.status === 200 && availableResponse?.data) {
      if (availableResponse?.data?.queue_number === "0"
        //&& benefit_balance > consultationFees
      ) {
        const appointmentResponse: any = await bookAppointment({
          "amount": Number(consultationFees),
          "beneficiary": entityId,
          // "beneficiary": 21810,
          "is_connect_now": 1,
          "doctor_id": Number(id),
          "hospital_id": Number(availableResponse?.data.hospital_id),
          "type_id": "1",
          "from_date": availableResponse?.data.from_date,
          "from_time": availableResponse?.data.from_time,
          "insurance_company_id": patientInsurance,
          "is_online": 1,
          "pay_type": "insurance",
          "to_date": availableResponse?.data.to_date,
          "to_time": availableResponse?.data.to_time,
          // "user_Id":43100,
          "user_id": userId ? Number(userId) : Number(patientInsuranceMember[0].principle_user_id),
          "pharmacy_id": getPharmacyIdFromLS(),
          // dependent_data: memberData
          // "user_id": userId?userId:null
        })

        if (appointmentResponse?.status === 200 && appointmentResponse?.data?.id) {
          getAccessToken(
            patientInsuranceMember[0].user_id
              ? patientInsuranceMember[0].user_id
              : patientInsuranceMember[0].principle_user_id
          ).then((res: any) => {
            localStorage.setItem("accessToken", res?.data.access_token)
            dispatch(loaderEnd());
            window.scroll({
              top: 0,
              behavior: 'smooth'
            });
            navigate(`/${ROUTE_NAME.APPOINTMENT}/${appointmentResponse?.data?.id}/meeting`)

          })
        } else {
          Alert(2, "Something went wrong!");
        }
      } else {
        Alert(2, `Doctor is occupied. Please wait for ${availableResponse?.data?.remaining_time}`);
      }
    }
  }
  // console.log(phoneNumber?.split("", 3), patientInsuranceMember[0]?.phone?.split("", 3), "phone", phoneNumber, patientInsuranceMember[0]?.phone)

  // console.log("Family mem", patientFamilyMemberData, insuranceMemberDependents, patientInsuranceMember)
  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h4" className='mb-20'>Patient Verification</Typography>
      <Box className='bg-white-with-padding border mb-20 cover skeuomorphic-with-padding'>
        {/* <Paper sx={{ p: 2 }}>           */}
        <Grid container spacing={3} sx={{ marginBottom: '40px' }}>
          <Grid item sm={12} md={4}>
            <Typography className={classes.label}>Select Your Insurance Company</Typography>
            <FormControl sx={{ width: '100%' }}>
              <Select
                className={classes.formItem}
                name="patientInsurance"
                size="small"
                value={patientInsurance}
                onChange={handleSelectChange}
              >
                {insuranceCompanies &&
                  insuranceCompanies?.length === 0
                  ? <MenuItem key={"No Insurance"}>{"No insurance company available"}</MenuItem>
                  : insuranceCompanies.map((option: any, index: any) => {

                    return (
                      <MenuItem key={index} value={option.id}>
                        <div className={classes.flexBox}>
                          <Avatar src={`${Env.BASE_URL}${option.avatar}`} variant="square"></Avatar>

                          <div className={classes.marginFlag}>{option.name}</div>
                        </div>
                      </MenuItem>
                    );
                  })
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={12} >
            {patientInsurance &&
              PatientInfo()
            }
          </Grid>

        </Grid>

        {insuranceMemberOptionSelect &&
          <FormControl sx={{ width: '100%', marginBottom: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={10} sm={6} md={3.5}>
                <Typography className={classes.label}>Patient Name</Typography>
                <TextField
                  className={`${classes.formItem} ${classes.w100}`}
                  name="PatientName"
                  size="small"
                  value={patientInsuranceMember[0].beneficiary_name}  //patientName
                />
              </Grid>
              <Grid item xs={10} sm={6} md={3.5}>
                <Typography className={classes.label}>Phone No.</Typography>
                <TextField
                  className={`${classes.formItem} ${classes.w100}`}
                  type="tel"
                  name="phoneNumber"
                  size="small"
                  // disabled
                  placeholder={"Phone No."}
                  // disabled={
                  //   age < 15 ||
                  //     (patientInsuranceMember &&
                  //       patientInsuranceMember.length > 0 &&
                  //       patientInsuranceMember[0].phone) ||
                  //     familyMemberPhoneChecked
                  //     ? true
                  //     : false
                  // }
                  value={["254", "375"].includes(phoneNumber?.split("", 3).join("")) ? phoneNumber.substring(3) : phoneNumber}
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        style={{
                          background: "rgba(0, 0, 0, .1) !important",
                        }}
                        position="start"
                      >
                        +254
                        {/* {patientInsuranceMember[0]?.phone?.split("", 3)} */}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ maxWidth: "100%" }}>
                {patientFamilyMemberData !== null &&
                  patientFamilyMemberData !== undefined &&
                  patientFamilyMemberData?.length > 0 &&
                  patientInsuranceMember !== undefined &&
                  patientInsuranceMember !== null &&
                  patientInsuranceMember.length > 0 &&
                  (patientInsuranceMember[0].phone === null ||
                    patientInsuranceMember[0].phone === "" ||
                    patientInsuranceMember[0].phone === undefined) ? (
                  <>
                    {insuranceMemberDependents !== null &&
                      insuranceMemberDependents !== undefined &&
                      insuranceMemberDependents.length > 0 ? (
                      <Grid item xs={12} sm={6} md={3} style={{ maxWidth: "100%" }}>
                        {insuranceMemberDependents !== undefined &&
                          insuranceMemberDependents !== null &&
                          insuranceMemberDependents.length > 0 ? (
                          <Typography className={classes.label}>{"Family Member Phone Number"}</Typography>
                        ) : !isPrincipal ? (
                          <Typography className={classes.label}>{"Principal Member Phone Number"}</Typography>
                        ) : (
                          <div style={{ display: "none" }}></div>
                        )}
                        <Select
                          name="familyMemberPhoneNumber"
                          className={classes.formItem}
                          size="small"
                          style={{ width: "100%" }}
                          value={familyMemberPhoneNumber}
                          id={userId}
                          onChange={(e: any) => {
                            dispatch({
                              type: 'SET_PATIENT_PROFILE',
                              payload: {
                                familyMemberPhoneNumber: e.target.value,
                                phoneNumber: e.target.value,
                                userId: insuranceMemberDependents.filter((item) => item.login_phone === e.target.value)[0].user_id
                              },
                            })
                          }}
                        >{insuranceMemberDependents !== null &&
                          insuranceMemberDependents !== undefined &&
                          insuranceMemberDependents.length > 0 &&
                          insuranceMemberDependents.map((option: any, index: any) => (
                            <MenuItem
                              key={index}
                              value={option.login_phone}
                            // id={option.user_id}
                            // onClick={(event: any) => {
                            //   debugger
                            //   console
                            // }
                            // }
                            >
                              <div className={classes.marginPhoneDropdown}>
                                {`${"+"}${option.login_phone} (${option.beneficiary_first_name} ${" "} ${option.beneficiary_last_name
                                  })`}
                              </div>
                            </MenuItem>
                          ))}</Select>

                        &nbsp;&nbsp;
                        {patientFamilyMemberData !== null &&
                          patientFamilyMemberData !== undefined &&
                          patientFamilyMemberData?.length > 0 &&
                          patientInsuranceMember !== undefined &&
                          patientInsuranceMember !== null &&
                          patientInsuranceMember?.length > 0 &&
                          (patientInsuranceMember[0]?.phone === null ||
                            patientInsuranceMember[0]?.phone === "" ||
                            patientInsuranceMember[0]?.phone === undefined) ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                className={classes.checkboxPhone}
                                disabled={true}
                                checked={true}
                                style={{ opacity: "none" }}
                              // onChange={handleChange}
                              />
                            }
                            label={`${"Use "}${insuranceMemberDependents !== null &&
                              insuranceMemberDependents !== undefined &&
                              insuranceMemberDependents.length > 0
                              ? "Family Member"
                              : "Principal Member"
                              }${" phone no."}`}
                          />
                        ) : (
                          <div style={{ display: "none" }}></div>
                        )}
                      </Grid>
                    ) : (insuranceMemberDependents === null || insuranceMemberDependents === undefined) &&
                      !isPrincipal ? (
                      <Grid item xs={12} sm={6} md={3}>
                        {insuranceMemberDependents !== undefined &&
                          insuranceMemberDependents !== null &&
                          insuranceMemberDependents.length > 0 ? (
                          <Typography className={classes.label}>{"Family Member Phone Number"}</Typography>
                        ) : !isPrincipal ? (
                          <Typography className={classes.label}>{"Principal Member Phone Number"}</Typography>
                        ) : (
                          <div style={{ display: "none" }}></div>
                        )}
                        <TextField
                          type="tel"
                          className="w-100"
                          name="principalPhoneNumber"
                          size="small"
                          placeholder={"Principal Member Phone Number"}
                          value={principalPhoneNumber}
                          inputProps={{
                            maxLength: 10,
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" disablePointerEvents={true}>
                                +{patientCountryCode}
                              </InputAdornment>
                            ),
                          }}
                        />
                        &nbsp;&nbsp;
                        {patientFamilyMemberData !== null &&
                          patientFamilyMemberData !== undefined &&
                          patientFamilyMemberData?.length > 0 &&
                          patientInsuranceMember !== undefined &&
                          patientInsuranceMember !== null &&
                          patientInsuranceMember?.length > 0 &&
                          (patientInsuranceMember[0]?.phone === null ||
                            patientInsuranceMember[0]?.phone === "" ||
                            patientInsuranceMember[0]?.phone === undefined) ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked}
                                onChange={handleChange}
                              />
                            }
                            label={`${"Use "}${insuranceMemberDependents !== null &&
                              insuranceMemberDependents !== undefined &&
                              insuranceMemberDependents.length > 0
                              ? "Family Member"
                              : "Principal Member"
                              }${" phone no."}`}
                          />
                        ) : (
                          <div style={{ display: "none" }}></div>
                        )}
                      </Grid>
                    ) : (
                      <div style={{ display: "none" }}></div>
                    )}
                  </>
                ) : (
                  <div style={{ display: "none" }}></div>
                )}

              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.label}>Employer Name</Typography>
                <TextField
                  fullWidth
                  name="employeename"
                  size="small"
                  //@ts-ignore
                  value={
                    patientInsuranceMember !== undefined && patientInsuranceMember.length > 0
                      ? patientInsuranceMember[0].policy_holder_name
                      : ""
                  }
                  className={`${classes.formItem} ${classes.w100}`}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.label}>Gender</Typography>
                <TextField
                  name="gender"
                  size="small"
                  placeholder={"gender"}
                  value={gender ? gender : getMale(patientInsuranceMember[0].policy_holder_name)}  //patientInsuranceMember[0].sex
                  className={`${classes.formItem} ${classes.w100}`}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.label}>Patient DOB</Typography>
                <TextField
                  name="patientDob"
                  size="small"
                  value={patientInsuranceMember[0].dob} //patientDoB
                  className={`${classes.formItem} ${classes.w100}`}
                />
              </Grid>
            </Grid>
          </FormControl>
        }

        {/* <Button onClick={proceedToCall}>Proceed to call</Button> */}
      </Box>
      {patientInsuranceMember[0]?.face_id &&
        <FR
          proceedToCall={proceedToCall}
          entityId={patientInsuranceMember[0]?.entity_id || entityId}
          faceId={patientInsuranceMember[0]?.face_id}
          insuranceStatus={policyStatus === 1 && !fundedBy}
          patientName={patientName}
          insuranceNumber={searchMember(patientInsuranceMember[0])}
          phoneNumber={phoneNumber}
        />
      }
      {showInfoPopup &&
        <CustomModal
          content={
            <Box sx={{ width: { lg: "1100px", md: 750, sm: 600, xs: 500 } }} className={classes.spaceBetween}>
              <Typography sx={{ fontSize: 20, textAlign: "left", margin: "20px 20px 20px 0px", width: "38%" }}>
                This is an Online Consultation. Your identity needs to be authenticated and matched to your insurance card.
              </Typography>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Typography sx={{ fontSize: 20, textAlign: "center", margin: "20px", width: "24%" }}>
                We use face biometric for authentication.
              </Typography>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Typography sx={{ fontSize: 20, textAlign: "left", margin: "20px 0px 20px 20px", width: "38%" }}>
              Please have your face biometrics registered first. Call Livia via <b>+254114081492</b> for support OR seek support from the counter if you are in the pharmacy.
              </Typography>
            </Box>
          }
          open={showInfoPopup}
          handleConfirm={() => {
            setShowInfoPopup(false);
            navigate('/');
          }}
          confirmButtonText={"OK"}
          loading={false}
        />
      }

      <Card sx={{ maxWidth: 250, position: "fixed", bottom: "30px", right: "30px" }}>
        <CardContent>
          <Typography variant="h5" className="mb-10">
            Customer Care
          </Typography>
          <Typography className={classes.flexBox}>
            <a
              style={{ color: "inherit", textDecoration: "none", paddingLeft: 4, display: "flex", alignItems: "center" }}
              key={"011412"}
              href={"tel: 0114 081492"}
              title={"Call: 0114 081492"}

            >
              <Phone sx={{ marginRight: '8px' }} /> {"0114 081492"}

            </a>

            {/* <Phone sx={{ marginRight: '8px' }} /> {"0114 081492"} */}
          </Typography>
          <Typography className={classes.flexBox}>
            <a
              style={{ color: "inherit", textDecoration: "none", paddingLeft: 4, display: "flex", alignItems: "center" }}
              key={"011412"}
              href={"tel: +254 732599737"}
              title={"Call: +254 732599737"}

            >
              <Phone sx={{ marginRight: '8px' }} /> {"+254 732599737"}

            </a>

          </Typography>
          <Typography className={classes.flexBox}>
            <a
              style={{ color: "inherit", textDecoration: "none", paddingLeft: 4, display: "flex", alignItems: "center" }}
              key={"011412"}
              href={"tel: +254 740869442"}
              title={"Call: +254 740869442"}

            >
              <Phone sx={{ marginRight: '8px' }} /> {"+254 740869442"}

            </a>
          </Typography>
        </CardContent>
      </Card>
    </ThemeProvider>

  )
}


export default VerifyPatient