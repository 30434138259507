import React from 'react'
import { Icon } from 'semantic-ui-react'

function UserTryingtoNavigate(props) {
  const { onHide } = props

  return (
    <div className="popup-cover">
      <div className="waiting-for-appointment popup-ui secondary-popup-ui">
        <div className="popup-header">
          <h2 className="text-blue bold center">
            <Icon name="attention" color="red"/>
            Attention
            </h2>
        </div>
        <div className="popup-body text-center">
          You can't navigate to other page during call. Please disconnect the
          call in order to move out from the page.
        </div>
        <div className="popup-footer">
          <button className="btn btn-simple small" onClick={onHide}>
            Okay
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserTryingtoNavigate
