import { Box, Typography } from '@mui/material';
import { currentYear, getPharmacyIdFromLS, getPharmacyNameFromLS } from '../../utils';

function Footer() {
  return (
    <Box sx={{ bgcolor: 'var(--bgfooter)', textAlign: 'center', py: 1.5 }} component="footer">
      <Typography variant='body2' sx={{ color: 'common.black' }}>
        © {currentYear()}{' '}
        {getPharmacyIdFromLS() && getPharmacyNameFromLS()
          ? getPharmacyNameFromLS()
          : "Livia Consultation Web App"}
        , All Rights Reserved.</Typography>
    </Box>
  );
}

export default Footer;
