import React, { useState } from "react";
import {
	RosterGroup,
	useRosterState,
	useMeetingEvent,
} from "amazon-chime-sdk-component-library-react";
import MuteContorls from "./muteContorls";

const MeetingRoster = () => {
	const { roster } = useRosterState();
	const meetingEvent = useMeetingEvent();

	let attendees = Object.values(roster);

	const attendeeItems = attendees.map((attendee) => {
		const { chimeAttendeeId } = attendee || {};

		return (
			<div key={chimeAttendeeId}>
				{meetingEvent &&
				meetingEvent?.attributes?.attendeeId === chimeAttendeeId ? (
					""
				) : (
					<MuteContorls key={chimeAttendeeId} attendeeId={chimeAttendeeId} />
				)}
			</div>
		);
	});

	return (
		<RosterGroup style={{ backgroundColor: "#edeaea" }}>
			{attendeeItems}
		</RosterGroup>
	);
};

export default MeetingRoster;
