import React from "react";
// import { roles } from "../../../utility/roleConstant";
// import { getRoleIdInLS } from "../../../utility/util";
import { useSelector } from "react-redux";

function UserIsNotOnlineForCall(props) {
  // const { apiData } = useSelector((state) => state?.websiteHome);
  const { name, onHide } = props;
  return (
    <div className="popup-cover">
      <div className="calling-popup popup-ui">
        <div
          className="popup-header"
          style={{
            backgroundImage: "linear-gradient(to right, #152567, #53c392)",
          }}
        >
          <h3 className="text-blue bold center">Alert</h3>
        </div>
        <div className="popup-body">
          <p className="center fs22">{name ? name : "User"} is not online.</p>
        </div>
        <div className="popup-footer">
          <button className="btn btn-primary fs22" onClick={onHide}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserIsNotOnlineForCall;
