import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type LoaderState = {
  isLoading: boolean;
};

const initialState = {
  isLoading: false
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    loaderStart: (state) => {
      state.isLoading = true;
    },
    loaderEnd: (state) => {
      state.isLoading = false;
    }
  }
});

export const { loaderStart, loaderEnd } = loaderSlice.actions;

export const selectLoader = (state: RootState) => state.loader.isLoading;

export default loaderSlice.reducer;
