import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Env, Image } from '../../constants';
import { getPharmacyIdFromLS, getPharmacyImageFromLS, getPharmacyNameFromLS } from '../../utils';
import { getDoctorListAsync, selectDoctor, doctorSearch, doctorSpecializationId, doctorOffset } from '../../pages/doctor/doctor.slice';
import { useDispatch } from 'react-redux';
import React, { useEffect } from "react";
function Header() {
  const pharmacyImage = getPharmacyIdFromLS() ? Env.BASE_URL + getPharmacyImageFromLS() : "";
  const dispatch = useDispatch()
  const favicon = document.getElementById("favicon")

  const [logoError, setLogoError] = React.useState(null)
  useEffect(() => {
    if (favicon && getPharmacyImageFromLS() && !logoError) {
      //@ts-ignore
      favicon.href = Env.BASE_URL + getPharmacyImageFromLS();
    } else {
      //@ts-ignore
      favicon.href = Image.LOGO
    }
  }, [logoError])
  return (
    <AppBar position="static" sx={{ bgcolor: 'common.white', position: "fixed", top: 0, zIndex: 999 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to={'/'}>
            <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => {
              dispatch(doctorSearch(""));
              window.scroll({
                top: 0,
                behavior: 'smooth'
              });
            }}>

              {pharmacyImage &&
                <img
                  src={logoError ? Image.LOGO : pharmacyImage}
                  alt="pharmacy avatar" width="50px"
                  onError={() => {
                    setLogoError(true)
                  }}
                />
              }
              <Typography variant="h6" sx={{ color: 'common.black', ml: 1 }}>
                {getPharmacyIdFromLS()
                  ? getPharmacyNameFromLS()
                  : ""
                }
              </Typography>
            </Box>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
