import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoute from './components/private-route/private.route';
import Home from './pages/home/home';

import { ROUTE_NAME } from './constants';
import "./style/buttons.css";
import "./style/popup-ui.css";
import "./style/common.css";
import "./style/reset.css";
import "./style/semantic-ui.css";
import Doctor from './pages/doctor/doctor';
import DoctorDetail from './pages/doctor/doctor-detail/doctor.detail';
import Chime from "./components/MeetingChime"
import MeetingNotificationWithSocket from "./components/socket/meeting.notification.with.socket"
import { useEffect, useState } from 'react';
import VerifyPatient from './pages/verifyPatient/verifyPatient';
import { getPhoneId, setDeviceIdInLS, getPharmacyIdFromLS, getPharmacyImageFromLS, Alert, getPharmacyNameFromLS } from './utils';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import { Box } from '@mui/material';
import UniqueCode from './components/unique-code/uniqueCode';
import { Env } from './constants';
function App() {
  const [title, setTitle] = useState("Consultation Web App");
  useEffect(() => {
    setDeviceIdInLS();
    getPhoneId();

    if(getPharmacyIdFromLS() && getPharmacyNameFromLS()) setTitle(getPharmacyNameFromLS());
  }, [])
  const favicon = document.getElementById("favicon")
  useEffect(() => {

    document.title = title ? title : "Consultation Web App";
    if (favicon && getPharmacyImageFromLS()) {
      //@ts-ignore
      favicon.href = Env.BASE_URL + getPharmacyImageFromLS();
    }
  }, [title]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path={ROUTE_NAME.HOME}
            element={
              getPharmacyIdFromLS()
                ? <PrivateRoute>
                  <Doctor />
                  <MeetingNotificationWithSocket />
                </PrivateRoute>
                : <>
                  <Header />
                  <Box className="main-content">
                    <UniqueCode />
                  </Box>
                  <Footer />
                </>
            }
          />
          <Route
            path={ROUTE_NAME.DOCTOR}
            element={
              getPharmacyIdFromLS()
                ? <PrivateRoute>
                  <Doctor />
                  <MeetingNotificationWithSocket />
                </PrivateRoute>
                : <Navigate to="/" />
            }
          />
          <Route
            path={`${ROUTE_NAME.DOCTOR_DETAIL}/:doctorId`}
            element={
              <PrivateRoute>
                <DoctorDetail />
                <MeetingNotificationWithSocket />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE_NAME.VERIFY_PATIENT}
            element={
              <PrivateRoute>
                <VerifyPatient />
                <MeetingNotificationWithSocket />
              </PrivateRoute>
            }
          />
          <Route
            path={"/appointments/appointment-detail/meeting-chime/:id/meeting"}
            element={
              <PrivateRoute isChime={true}>
                <Chime />
                <MeetingNotificationWithSocket />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
