import { Body, DoctorState, DoctorDetailDTO } from "../types";

export const doctorInitialState: DoctorState = {
  data: [] as Body[],
  offset: 0,
  limit: 9,
  specializationId: "all",
  isOnline: 1,
  search: "",
  pharmacyConsultation: 1,
  count: 0,
};

export const doctorDetailsInitialState: DoctorDetailDTO = {
  id: "",
  email: "",
  doctor_phone: "",
  avatar: "",
  user_status: "",
  description: "",
  reception_phone_numbers: [],
  phone: "",
  hospital_id: "",
  qualification: "",
  available_now: "",
  profile_views: "",
  experience_years: "",
  is_favourite: "",
  name: "",
  online_consultation_fees: "",
  consultation_fees: "",
  insurance_companies: [],
  specializations: [],
  doctors_photos: [],
  doctor_hospitals:[]
  
};
