import React, { useMemo, useEffect } from "react";
import { toast } from "react-toastify";

const useAudio = (url) => {
	// const context = new AudioContext();
	const audio = useMemo(() => new Audio(url), []);

	const play = () => {
		const audioPlay = audio.play();
		audioPlay
			.then(() => {
				console.log("playing sound !!!");
			})
			.catch((e) => {
				;
				toast.warn(`Please allow audio permissions.`, {
					className: "toast-warn",
				});
				console.log(e);
			});
	};
	const stop = () => {
		if (audio) {
			audio.pause();
			audio.currentTime = 0;
		}
	};
	// React.useEffect(() => {
	// 	if (context?.state === "suspended") {
	// 		;
	// 		context.resume().then(() => {
	// 			console.log("Playback resumed successfully");
	// 		});
	// 	}
	// }, [context]);
	useEffect(() => {
		audio.loop = "loop";
		audio.addEventListener("ended", () => {});
		return () => {
			audio.removeEventListener("ended", () => {});
		};
	}, []);

	return [play, stop];
};

export default useAudio;
