import React from "react";
import { Env } from "../../constants";
import doctopPlaceHolder from "../../assets/image/doctor_placeholder.svg";

function CallKit(props) {
  const { res, cancelCallHandler, acceptCallHandler, isAddFaqApiLoading } =
    props;
  return (
    <div className="popup-cover">
      <div className="pay-bill-popup popup-ui">
        <div className="popup-body">
          <div className="incoming-call-toaster">
            <h2 className="bold center mb-10">Consultation Call</h2>
            <p className="center bold">{`${res.result.fromUserName} is calling you...`}</p>
            <div
              style={{
                width: 55,
                height: 55,
                borderRadius: "50%",
                overflow: "hidden",
                border: "1px solid #d3d3d3",
                margin: "20px auto",
              }}
            >
              <img
                src={
                  res?.result?.eventData?.user_avatar
                    ? `${Env.BASE_URL + res?.result?.eventData?.user_avatar}`
                    : doctopPlaceHolder
                }
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
              // className="btn-cover"
              
            >
              <button
                className="btn btn-delete mr-20"
                onClick={cancelCallHandler}
              >
                Decline
              </button>
              <button
                className="btn btn-primary"
                onClick={() => acceptCallHandler(res)}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallKit;
