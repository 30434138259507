export const httpEndpoints = {
	GET: "get",
	POST: "post",
	PUT: "put",
	PATCH: "patch",
	DELETE: "delete",
};

export const endpoints = {
	SETTINGS: "/settings",
	LABREQUEST: "/lab-request",
	LABREPORT: "/lab-report",
	MYPROFILE: "/hospital-profile",
	PAYMENT: "/hospital-payment",
	BRANCH: "/hospital-profile",
	USER: "/hospital-user",
	HOSPITAL_SIGNUP: "/hospital-signup",
	PREAUTH: "/hospital-pre-auth",
	INSURANCE_COMPANY: "/insurance-company",
	INSURANCE_COMPANY_MEMBER: "/insurance-company-member",
	INVITING_DEPENDENT: "/inviting-dependent",
	HOSPITALCLAIM: "/claim",
	HOSPITALCLAIMTREATMENT: "/claim-treatment",
	INSURANCE_PAYMENT_OPTION: "/insurance-payment-option",
	HOSPITAL_DOCTOR: "/hospital-doctor",
	CREATEPREAUTH: "/hospital-pre-auth",
	ORDER: "/order",
	DIARY: "hospital-diary",
	CHIME: "/chime",
	EVENT: "/event",
	PAYMENT_CALLBACK: "/app-payment-call-back",
	WORKINGHOURS: "/working-hours",
	CALENDAR: "/calendar",
	WEBSITELOGIN: "/auth",
	WEBSITEUSER: "/user",
	HOSPITAL_HOME: "/hospital-home",
	HOSPITAL_MGMT_WEBSITE: "/hospital-website-management",
	DOCTOR: "/doctor",
	HOSPITAL_USER: "/hospital-user",
	SCHEME: "/scheme",
	HOSPITAL_EVENT: "/hospital-event",
	WITHDRAWAL: "/withdrawal",
	NOTIFICATION: "/hospital-index",
};

//export const chimeMeetingSocketApiUrl = "https://liviasocket.appskeeper.in/";
export const chimeMeetingSocketApiUrl = "https://socketstg.liviaapp.com/";
// Staging: https://liviasocket.appskeeper.in/
// Production: https://socketstg.liviaapp.com/
