//@ts-nocheck
import { Container, Box, SelectChangeEvent, Grid, MenuItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import "./doctors.css";
import { Pagination } from '@mui/material';
import SearchTextfield from '../../components/form-ui/search-textfield/search.textfield';
import SelectWithChild from '../../components/form-ui/select-wrapper/select.with.child';
import DoctorCard from '../../components/doctor-card/doctor.card';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getDoctorListAsync, selectDoctor, doctorSearch, doctorSpecializationId, doctorOffset } from './doctor.slice';
import { useNavigate } from 'react-router-dom';
import { getSpecialization } from './doctor.api';
import { Specializations, SpecializationsDTO } from '../../types';
import { AxiosResponse } from 'axios';
import { ROUTE_NAME, SUCCESSFUL } from '../../constants';
import useDebounce from '../../hooks/use.debounce';
import { SPECIALITY_FOR_ALL } from '../../utils';
import { selectLoader } from '../../features/loader/loader.slice';

function Doctor() {
  const navigate = useNavigate();
  const { data, offset, limit, specializationId, isOnline, search, count, pharmacyConsultation } =
    useAppSelector(selectDoctor);
  const isLoading = useAppSelector(selectLoader)
  const dispatch = useAppDispatch();
  const [specialization, setSpecialization] = useState([] as Specializations[]);
  const debouncedSearch = useDebounce(search, 500);
  const [page, setPage] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [doctorList, setDoctorList] = useState([]);

  useEffect(() => {
    getSpecialization().then((res: AxiosResponse<SpecializationsDTO>) => {
      if (res.status === SUCCESSFUL.OK) setSpecialization([SPECIALITY_FOR_ALL, ...res.data.body]);
    });
    return () => {
      dispatch(doctorSearch(""));
    }
  }, []);

  useEffect(() => {
    dispatch(
      getDoctorListAsync({
        offset,
        limit,
        isOnline,
        pharmacyConsultation,
        ...(search ? { search: debouncedSearch } : { search: '' }),
        ...(specializationId ? { specializationId } : { specializationId: '' })
      })
    );
  }, [debouncedSearch, specializationId]);

  // useEffect(() => {
  //   if ((count - offset) > 6) {
  // dispatch(doctorSearch(keyword));
  // dispatch(
  //   getDoctorListAsync({
  //     offset,
  //     limit,
  //     isOnline,
  //     pharmacyConsultation,
  //     ...(search ? { search: debouncedSearch } : { search: '' }),
  //     ...(specializationId ? { specializationId } : { specializationId: '' })
  //   })
  // );
  // }
  // setDoctorList(data?.slice(offset, pageOffset + 6))
  //   else
  //     setDoctorList(data?.slice(pageOffset, data?.length))
  // }, [data, page, pageOffset])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    dispatch(doctorSearch(keyword));
    setPage(1)
    setPageOffset(0)
  };

  const handleSearchSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    dispatch(doctorSearch(keyword));
  };

  const handleChangeFilter = (event: SelectChangeEvent<unknown>) => {
    const filterBy = event.target.value as string;
    dispatch(doctorSpecializationId(filterBy));
  };

  const handleConnectNow = (item: any) => {
    dispatch({
      type: 'SET_DOCTOR_DETAILS',
      payload: item
    });
    navigate("/verify-patient");
  }

  const handleProfileClick = (id: string) => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    navigate(`/${ROUTE_NAME.DOCTOR_DETAIL}/${id}`);
  }

  const handlePageChange = (event: any, value: number) => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    dispatch(
      getDoctorListAsync({
        offset: (value - 1) * limit,
        value,
        limit,
        isOnline,
        pharmacyConsultation,
        ...(search ? { search: debouncedSearch } : { search: '' }),
        ...(specializationId ? { specializationId } : { specializationId: '' })
      })
    );
    setPage(value);
    // setPageOffset((value - 1) * 6);
  };

  return (
    <div className="page-doctors" style={{}}>
      <Box sx={{ bgcolor: 'secondary.main', py: 1, position: "fixed", top: 65, width: "92%", zIndex: 99 }} >
        <Container maxWidth="xl" sx={{ height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <SearchTextfield
            name="searchInput"
            placeholder="Search by Doctor name, Specialty or Clinic name"
            inputValue={search}
            handleSearchChange={handleSearchChange}
            handleSearchSubmit={handleSearchSubmit}
          />
        </Container>
      </Box>
      {isLoading ? <></> : <Container maxWidth="xl" sx={{ marginTop: 9, }} >

        <Grid container spacing={2}>
          {data.length > 0 ? (
            data.map((item) =>
              <div item xs={12} sm={6} md={3} key={item.id} className="single-doctor">
                <DoctorCard
                  img={item?.avatar}
                  name={item?.name}
                  view={item?.profile_views}
                  exp={item?.experience_years}
                  qualification={item?.qualification}
                  speciality={item?.specializations}
                  isButton
                  onClick={() => handleProfileClick(item.id)}
                  onClickConnect={() => handleConnectNow(item)}
                  elips={true}
                />
              </div>
            )
          ) : (
            <Typography variant="h6" sx={{ margin: '40px auto' }}>
              No doctor found. Please search with different name.
            </Typography>
          )}
        </Grid>
        {+count > 0 &&
          <Box sx={{ padding: '20px 0', display: 'flex', justifyContent: 'flex-end' }}>
            <Pagination
              shape="rounded"
              size="large"
              variant="outlined"
              count={Math.ceil(count / limit)}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        }
      </Container>}
    </div>
  );
}

export default Doctor;
